import React from "react";
import Header from "../header/Header";
import CreatePicForm from "./components/CreatePlcForm";

import "./createPic.css";

export default function CreatePicPage() {
  return (
    <div>
      <Header />
      <div className="container"></div>
      <CreatePicForm />
    </div>
  );
}
