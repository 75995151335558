import GraphData from "../models/GraphData";
import SensorData from "../models/SensorData";

const BASE_URL = localStorage.getItem("BASE_URL");
const getSensorId = localStorage.getItem("SensorId")
const getLineChartIntervalQuery = (interval: string) => {


  if (interval === "2hour") {
    return "&labelType=DATETIME&offSetValue=-7200&interval=SECOND";
  }
  if (interval === "2hourA") {
    return "&labelType=DATETIME&offSetValue=-7200&interval=SECOND&average=1";
  }
  if (interval === "6hour") {
    return "&labelType=DATETIME&offSetValue=-21600&interval=SECOND";
  }
  if (interval === "6hourA") {
    return "&labelType=DATETIME&offSetValue=-21600&interval=SECOND&average=1";
  }
  if (interval === "12hour") {
    return "&labelType=DATETIME&offSetValue=-43200&interval=SECOND";
  }
  if (interval === "12hourA") {
    return "&labelType=DATETIME&offSetValue=-43200&interval=SECOND&average=1";
  }
  if (interval === "DAY") {
    return "&labelType=DATETIME&offSetValue=-172800&interval=SECOND";
  }
  if (interval === "DAYA") {
    return "&labelType=DATETIME&offSetValue=-172800&interval=SECOND&average=1";
  }

  if (interval === "WEEK") {
    return "&labelType=DATE&offSetValue=-604800&interval=SECOND";
  }

  if (interval === "MONTH") {
    return "&labelType=DATE&offSetValue=-2592000&interval=SECOND";
  }
  if (interval === "HALF YEAR") {
    if (BASE_URL === "http://172.30.8.27:8086") {
      return "&labelType=DATE&offSetValue=-15552000&interval=SECOND";
    }
    else {
      return "&labelType=DATE&offSetValue=-15552000&interval=SECOND&boxplot=1";
    }
  }
  if (interval === "FULL YEAR") {
    return "&labelType=DATE&offSetValue=-31104000&interval=SECOND&boxplot=1"
  }

  return "&labelType=DATETIME&offSetValue=-172800&interval=SECOND";
};

const getAllSensorsWithPlcId = async (plcId: number): Promise<SensorData[]> => {
  const response = await (
    await fetch(`${BASE_URL}/sensor-type?plcId=${plcId}`)
  ).json();
  const data: SensorData[] = response.data;
  return data;
};

const getSensorDataFiltered = async (
  sensorId: number,
  filterDate: string,
  lineChartInterval: string
): Promise<GraphData[]> => {
  if (sensorId === -1) {
    return [];
  }

  let interval: string = getLineChartIntervalQuery(lineChartInterval);

  const response = await fetch(
    `${BASE_URL}/plc-config/sensor/${sensorId}/graph?toDate=${filterDate}${interval}`
  );
  const responseJson = await response.json();
  const sensorData: GraphData[] = responseJson.data;
  return sensorData;
};

const getSensorDataFilteredWithCustomDate = async (
  sensorId: number,
  filterDate: string,
  lineChartInterval: string,
  dateDifference: number
): Promise<GraphData[]> => {
  if (sensorId === -1) {
    return [];
  }

  let interval: string = "";

  if (dateDifference < 7) {
    interval = `&labelType=DATETIME&offSetValue=-${dateDifference * 24
      }&interval=SECOND`;
  }
  else if (dateDifference < 172801) {
    interval = `&labelType=DATETIME&offSetValue=-${dateDifference}&interval=SECOND`;
  }
  else {


    interval = `&labelType=DATE&offSetValue=-${dateDifference}&interval=SECOND`;
  }

  const response = await fetch(
    `${BASE_URL}/plc-config/sensor/${sensorId}/graph?toDate=${filterDate}${interval}`
  );
  const responseJson = await response.json();
  const sensorData: GraphData[] = responseJson.data;
  return sensorData;
};

const getMultiSensorDataFilteredWithCustomDate = async (
  sensorIdList: number[],
  filterDate: string,
  fromDate: string,
  dateDifference: number
): Promise<GraphData[][]> => {
  if (sensorIdList.length < 1) {
    return [];
  }

  let interval: string = "";
  let offsetValue: number = 0;
  let labelType: string = "DATETIME";


  const response = await fetch(`${BASE_URL}/plc-config/sensor/graph`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sensorIds: sensorIdList,
      // interval: "HOUR",
      // offsetValue: -offsetValue == 0 ? -1 : -offsetValue,
      labelType: labelType,
      toDate: filterDate,
      fromDate: fromDate
    }),
  });
  const responseJson = await response.json();

  const sensorData: GraphData[][] = responseJson.data;
  return sensorData;
};

export {
  getAllSensorsWithPlcId,
  getSensorDataFiltered,
  getSensorDataFilteredWithCustomDate,
  getMultiSensorDataFilteredWithCustomDate,
};
