//@ts-ignore
import GaugeChart from "react-gauge-chart";
import { Row, Col, Card } from "antd";
import ReactSpeedometer, {
  CustomSegmentLabelPosition,
} from "react-d3-speedometer";
import { useState } from "react";
import { MetricsContext } from "../providers/MetricsProvider";

const getGaugeNeedleColor = (
  upperRange: number,
  lowerRange: number,
  value: number
): any => {
  if (value <= lowerRange || value >= upperRange) {
    return { color: "red" };
  }
  return { color: "green" };
};

export default function SensorGauge(props: any) {
  let desc = props.data.description.replaceAll(" ", "");

  const getDefaultValue = (currentValue: Number) => {
    let a;
    if (currentValue <= props.data.sensorLowerRange) {
      a = props.data.sensorLowerRange;
    } else if (currentValue >= props.data.sensorUpperRange) {
      a = props.data.sensorUpperRange;
    } else if (
      currentValue > props.data.sensorLowerRange &&
      currentValue < props.data.sensorUpperRange
    ) {
      a = Number(currentValue);
    }
    return a;
  };

  // ((val - (min)) / (max - (min)))

  return (
    <>
      <Card>
        {/* {props.data.description} */}
        <h2 className="temp-name">{props.data.description}</h2>
        <img
          onClick={() =>
            (window.location.href =
              window.location.href.replace(
                "current_sensor_stats",
                "over_view"
              ) +
              "&currentActiveSnsor=" +
              props.data.sensorId)
          }
          className="dashboard-item-images"
          src={`${localStorage.getItem(
            "BASE_URL"
          )}/sensor/image?name=${desc}.gif`}
          alt={props.data.description}
          style={{ marginBottom: 20, cursor: "pointer" }}
        />
        <Row
          className={
            props.data.sensorUpperRange < props.data.lastReading || props.data.lastReading < props.data.sensorLowerRange
            ? "guage-color-red"
            :props.data.upperWarningLimit > props.data.lastReading && props.data.lastReading > props.data.lowerWarningLimit 
             ?"guage-color-green":"guage-color-yellow"
            // props.data.sensorUpperRange < props.data.lastReading ||
            // props.data.sensorLowerRange > props.data.lastReading
            //   ? "guage-color-yellow"
            //   : "guage-color-green"
          }
        >
          <Col md={24}>
            <ReactSpeedometer
              height={170}
              width={240}
              forceRender={true}
              needleHeightRatio={0.7}
              value={getDefaultValue(props.data.lastReading)}
              minValue={props.data.sensorLowerRange}
              maxValue={props.data.sensorUpperRange}
              segments={3}
              customSegmentStops={[
                props.data.sensorLowerRange,
                props.data.lowerWarningLimit,
                props.data.upperWarningLimit,
                props.data.sensorUpperRange,
              ]}
              currentValueText={
                String(props.data.lastReading.toFixed(2)) +
                ` ${props.data.unit || ""}`
              }
              segmentColors={["#FFBF00", "#00FF00", "#FFBF00"]}
              ringWidth={10}
              needleTransitionDuration={3333}
              needleColor={
                props.data.sensorUpperRange < props.data.lastReading || props.data.lastReading < props.data.sensorLowerRange
                  ? "red"
                  :props.data.upperWarningLimit > props.data.lastReading && props.data.lastReading > props.data.lowerWarningLimit 
                   ?"grey":"#FFBF00"
              }
              // needleColor={
              //   props.data.lastReading >= props.data.upperWarningLimit ||
              //     props.data.lastReading <= props.data.lowerWarningLimit
              //     ? "red"
              //     : "green"
              // }
            />
          </Col>
        </Row>
      </Card>
      <div></div>
    </>
  );
}
