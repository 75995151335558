import React, { createContext, useState } from "react";
import DashBoardContextType from "../../types/DashboardContextType";

export const DashboardContext = createContext<DashBoardContextType>(
  {} as DashBoardContextType
);

export const DashboardContextProvider = (props: any) => {
  const [machineList, setMachineList] = useState([]);

  return (
    <DashboardContext.Provider
      value={{
        machineList,
        setMachineList,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
