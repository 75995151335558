import Header from "../header/Header";
import CreateSensorForm from "./components/CreateSensorForm";

import "./createPic.css";

export default function CreateSenortPage() {
  return (
    <div>
      <Header />
      <div className="container"></div>
      <CreateSensorForm />
    </div>
  );
}
