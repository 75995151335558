import { Fragment } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import { SettingFilled  } from '@ant-design/icons';
import  Image  from "../common/Image";

export default function header() {

  const BASE_URL = localStorage.getItem("BASE_URL");
  var url_string = window.location.href;
  var url = new URL(url_string);
  var db = url.searchParams.get("db");
// console.log(db);
// console.log(BASE_URL);

// console.log(document.location.pathname);



  const urlLink = (val: any) => {
    if (val === "CF") {
      return "http://172.30.8.27/shimanov2/Main_CF.php"
    } else if (val === "STP") {
      return "http://172.30.8.27/shimanov2/Main_STP.php"
    }
    else if (val === "MS") {
      return "http://172.30.8.27/shimanov2/Main_MS.php"
    }
    else if (val === "HT") {
      return "http://172.30.8.27/shimanov2/Main_HT.php"
    }
    else if(val === ""){
      return "http://172.30.8.27/shimanov2/sadmin/dashboard.php"
    }
    else if(val === "MF"){
      return "http://172.30.8.27/shimanov2/Main_M&F.php"
    }
    else if(val==="TC"){
      return "http://172.30.8.27/shimanov2/Main_TC.php"
    }
    else if(val==="EE"){
      return "http://172.30.8.27/shimanov2/Main_EE.php"
    }
    else if(val==="QC"){
      return "http://172.30.8.27/shimanov2/Main_QC.php"
    }
   
  }


  return (
    <Fragment>
      <div className="header">
        <div className="logo-area">
          {document.location.pathname ===
            "/environmental_condition_monitoring" || db === "/environmental_condition_monitoring" || document.location.pathname === "/configuremap"
            || document.location.pathname === "/floormap"
            || document.location.pathname === "/FormFloorPlan"
            ?
            <Link to="/environmental_condition_monitoring">
              <img
                src={require("../../assets/header/logo.png")}
                className="header-logo"
                alt="Shimano Logo - Corpfield Technologies"
              />
              <img
                src={require("../../assets/header/small-logo.png")}
                className="header-logo2"
                alt="Shimano Logo - Corpfield Technologies"
              />
            </Link> :
            <Link to="/machine_condition_monitoring">
              <img
                src={require("../../assets/header/logo.png")}
                className="header-logos"
                alt="Shimano Logo - Corpfield Technologies"
              />
               <img
                src={require("../../assets/header/small-logo.png")}
                className="header-logo2"
                alt="Shimano Logo - Corpfield Technologies"
              />
            </Link>
          }
        </div>
        <div className="db-area">
          {document.location.pathname ===
            "/environmental_condition_monitoring" || db === "/environmental_condition_monitoring"
            || document.location.pathname === "/configuremap"
            || document.location.pathname === "/floormap"
            || document.location.pathname === "/FormFloorPlan"
            ?
            <h3>Environmental Condition Monitoring (EnvCM)</h3>
            : null}
          {document.location.pathname ===
            "/machine_condition_monitoring" || db === "/machine_condition_monitoring" ?
            <h3> Machine Condition Monitoring (MCM)</h3>
            : null}
        </div>
        <div className="dashboard-link">
          <a href={String(urlLink(localStorage.getItem("DEPARTMENT")))} >
            <p className="navbar-item">
              <img
                className="navbar-item"
                src={require("../../assets/header/header-menu.png")}
                alt="Dashboard Menu "
              />Dashboard</p>
          </a>
          
          {
          document.location.pathname ==="/environmental_condition_monitoring"
          ||document.location.pathname ==="/configuremap"
          ||document.location.pathname ==="/floormap"
          ||document.location.pathname ==="/FormFloorPlan"
          ?
          <Fragment>
            { localStorage.getItem("ROLE")==="admin"?  
             <a className="settingicon"><Link to={'/configuremap'}>
             {/* <SettingFilled  /> */}
             {/* <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/settings--v1.png" alt="settings--v1"/> */}
             {/* <img width="30" height="30" src="https://img.icons8.com/ios-filled/50/FFFFFF/advanced-search.png" alt="advanced-search"/> */}
             <Image name="setting.png" className={"settingpng"}/>

             
           </Link></a>:null}
            
          </Fragment>
          
         
          :null}
      
        </div>
      </div>
    </Fragment >
  );
}
