import React, { useContext, useState, useEffect } from "react";
import { MetricsContext } from "../providers/MetricsProvider";
import {
  getSensorDataFiltered,
  getSensorDataFilteredWithCustomDate,
} from "../services/MetricsService";
import { getAllSensorsWithPlcId } from "../services/MetricsService";
import { Row, Col, Spin, DatePicker } from "antd";
import moment, { Moment } from "moment";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { getDiffernceOfDateInDays, getDiffernceOfDateInSeconds } from "../../common/utils";
import LineGraph from "./LineGraph";
import WhiskerPlot from "../../whiskerGraph/WhiskerPlot";

const { RangePicker } = DatePicker;
const BASE_URL = localStorage.getItem("BASE_URL");

// client
// const url_string = "http://172.30.8.27:8085";
// local
const url_string = "https://shimanoapi.corpfield.com";


export const changeDateFormat = (date: any) =>
  moment(date).format("YYYY-MM-DD HH:mm");

export default function LineGraphFilter() {
 
  // var url = new URL(url_string);
  // var SenID = url.searchParams.get("sId");
  const metrixContext = useContext(MetricsContext);
  const currentActiveSensor = useContext(MetricsContext).currentActiveSensor;
  const lineChartFilterDate = metrixContext.lineChartFilterDate;
  const lineChartInterval = metrixContext.lineChartInterval;
  console.log(lineChartInterval);
  let getSensorId = localStorage.getItem("SensorId")
  const params = useParams();

  let search = window.location.search;
  let params1 = new URLSearchParams(search);
  const [whiskergraph, setwhiskergraph] = useState(false)
  const [loading, setLoading] = useState(false as any)
  // console.log(currentActiveSensor);
  // console.log(currentActiveSensor.sensorId);
  // console.log(getSensorId);
  console.log(getSensorId);




  useEffect(() => {
    setLoading(false);
    if (currentActiveSensor.sensorId === undefined) return;
    getSensorDataFiltered(
      currentActiveSensor.sensorId,
      lineChartFilterDate,
      lineChartInterval
    ).then((sensorData) => {
      console.log(sensorData);

      if (BASE_URL === url_string) {
        if (lineChartInterval === "HALF YEAR" || lineChartInterval === "FULL YEAR") {
          setwhiskergraph(true)
        }
        else {
          setwhiskergraph(false)
        }
      }

      metrixContext.setSensorData(sensorData);
      setLoading(true);
    });
  }, [currentActiveSensor, lineChartFilterDate, lineChartInterval]); // eslint-disable-line

  const updateLineChart = async (e: any) => {
    getSensorId = null
    localStorage.removeItem("SensorId");
    let sensorId: number = e.target.value;
    metricsContext.machineSensorList.forEach((sensor) => {
      if (sensorId == sensor.sensorId) {
        metricsContext.setCurrentActiveSensor(sensor);
      }
    });
    getSensorDataFiltered(
      sensorId,
      lineChartFilterDate,
      lineChartInterval
    ).then((sensorData) => {
      console.log(sensorData);

      metrixContext.setSensorData(sensorData);
    });
  };

  const updateFilterDate = (momentDate: any) => {

    let date = moment(momentDate._d).format("YYYY-MM-DD");
    metrixContext.setLineChartFilterDate(date);
  };

  const [date, setDate] = useState(null);

  const disabledDate = (current: any) =>
    current && current > moment().endOf("day");

  const updateFilterInterval = (e: any) => {
    metrixContext.setLineChartInterval(e.target.value);
    setDate(e.target.value);
  };

  const onChangeFilter = (dateString: any) => {
    console.log(dateString[0]._d);

    let fromDate: string = changeDateFormat(dateString[0]._d);
    let toDate: string = changeDateFormat(dateString[1]._d);
    let dateDifference: number = getDiffernceOfDateInSeconds(fromDate, toDate);
    console.log(dateDifference);

    setLoading(false)
    getSensorDataFilteredWithCustomDate(
      metricsContext.currentActiveSensor.sensorId,
      toDate,
      lineChartInterval,
      dateDifference
    ).then((sensorData) => {
      metrixContext.setSensorData(sensorData);
      setLoading(true)
    });
  };

  const plcId: number = params.plcId === undefined ? 0 : Number(params.plcId);

  const metricsContext = useContext(MetricsContext);
  useEffect(() => {
    if (localStorage.getItem("SensorId")) {

      // currentActiveSensor.sensorId=Number(getSensorId)
      metricsContext.machineSensorList.forEach((sensor) => {
        if (Number(getSensorId) == sensor.sensorId) {
          metricsContext.setCurrentActiveSensor(sensor);
        }
      });
    }
  })
  useEffect(() => {

    // if (localStorage.getItem("SensorId")) {
    //   console.log("hi");

    //   // currentActiveSensor.sensorId=Number(getSensorId)
    //   metricsContext.machineSensorList.forEach((sensor) => {
    //     if (Number(getSensorId) == sensor.sensorId) {
    //       metricsContext.setCurrentActiveSensor(sensor);
    //     }
    //   });
    // }
    // else {


    getAllSensorsWithPlcId(plcId).then((data) => {
      let currentActiveSensorFromParams = params1.get("currentActiveSnsor");
      metricsContext.setMachineSensorList(data);
      if (
        currentActiveSensorFromParams !== undefined &&
        currentActiveSensorFromParams !== null
      ) {
        data.forEach((s) => {
          if (s.sensorId === Number(currentActiveSensorFromParams)) {
            metricsContext.setCurrentActiveSensor(s);
          }
        });
      }
      else if (localStorage.getItem("SensorId")) {
        if (Number(getSensorId)) {
          metricsContext.setCurrentActiveSensor(Number(getSensorId));
        }
      }
      else {
        metricsContext.setCurrentActiveSensor(data[0]);
      }
    });
    // }







  }
    , []); // eslint-disable-line

  return (
    <Fragment>
      <Row className="over-view">
        <Col md={14} xs={7}>
          {/* <h1>Overview</h1> */}
        </Col>
        <Col md={2} xs={7}>
          <select
            // defaultValue={"DAY"}
            className="metrics-filter-date"
            onChange={updateFilterInterval}
            value={lineChartInterval}
          >
            {BASE_URL === url_string ?
              <Fragment>
                <option value={"2hour"}>2 hour (Raw)</option>
                <option value={"2hourA"}>2 hour (Averaging)</option>
                <option value={"6hour"}>6 hour (Raw)</option>
                <option value={"6hourA"}>6 hour (Averaging)</option>
                <option value={"12hour"}>12 hour (Raw)</option>
                <option value={"12hourA"}>12 hour (Averaging)</option>

              </Fragment>
              : null}

            <option value={"DAY"}>2 Days (Raw)</option>
            {BASE_URL === url_string ? 
            <option value={"DAYA"}>2 Days (Averaging)</option> 
            : null}
            <option value={"WEEK"}>7 Days (Averaging)</option>
            <option value={"MONTH"}>30 Days (Averaging)</option>


            {BASE_URL === url_string ?
              <Fragment>
                <option value={"HALF YEAR"}>180 Days (Boxplot)</option>
                <option value={"FULL YEAR"}>360 Days (Boxplot)</option>
              </Fragment>
              : <option value={"HALF YEAR"}>180 Days (Averaging)</option>}

            <option value="Add">Custom Filter</option>

          </select>
        </Col>
        <Col md={1} xs={7}></Col>
        <Col md={4} xs={7}>
          {date === "Add" ? (
            <RangePicker
              showTime
              onChange={onChangeFilter}
              disabledDate={disabledDate}
            />
          ) : (
            <DatePicker
              className="metrics-filter-date"
              showTime={{
                format: 'HH:mm',
                // use12Hours: true
              }}
              // type="date"
              defaultValue={moment()}
              format="YYYY-MM-DD"
              onChange={updateFilterDate}
              disabledDate={disabledDate}
            />
          )}
        </Col>
        <Col md={3} xs={7}>
          <select onChange={updateLineChart} className="metrics-select-filter-date ">
            {metrixContext?.machineSensorList?.map((sensorData, index) => {
              return (
                <option
                  value={sensorData.sensorId}
                  key={index}
                  selected={
                    sensorData.sensorId === currentActiveSensor.sensorId
                  }
                >
                  {sensorData.description}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      {loading ?
        <Fragment>

          {whiskergraph === true ?
            <WhiskerPlot /> :
            <LineGraph />
          }
        </Fragment>
        : <div className="dashboard-loading">
          <Spin tip="Loading...." size="large">
            <div className="content" />
          </Spin>
        </div>
      }


      {/* {lineChartInterval==="HALF YEAR" || lineChartInterval==="FULL YEAR"?
<WhiskerPlot data={metricsContext.sensorData}/>:
<LineGraph />
} */}
    </Fragment>
  );

}
