import React, { Fragment, useState, useEffect } from "react";
import { Button, Row, Col, Form, Input, Upload, Select, message, Space } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Image from '../../../Image'
import Header from '../../../../header/Header';
import DashboardHeader from "../../../../dasboard/components/DashboardHeader";
import { DashboardContextProvider } from "../../../../dasboard/providers/DashboardProvider";
import "./style.css";
import { SaveOutlined, SyncOutlined } from '@ant-design/icons';


const { Option } = Select;


const BASE_URL = localStorage.getItem("BASE_URL");
const FormFloorPlan = () => {

    // const create = window?.location?.pathname === "/FormFloorPlan/create"
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [files, setFiles] = useState(null);

    const [listData, setListData] = useState(null);
    const paramsfloorId = useLocation();

    useEffect(() => {
        fetch(`${BASE_URL}/plc-config/getFloorDetails?floorId=${paramsfloorId?.state}`)
            .then(response => response.json())
            .then(data => {
                setListData(data.data)
                form.setFieldsValue({ "floorName": data.data?.floorName })
                form.setFieldsValue({ "floorImage": null })
            });
    }, [])

    const onFinish = (values) => {
        const payload = new FormData();
        if (paramsfloorId.state !== null) {
            payload.append('floorId', paramsfloorId.state);
        }


        payload.append('floorName', values.floorName);
        if (files !== null) {
            payload.append("floorImage", files[0]?.originFileObj)
        }
        fetch(`${BASE_URL}/plc-config/addOrEditFloor`, {
            method: 'POST',
            body: payload
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {
                    navigate('/configuremap');
                }

            });
    }
    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: 'images/*',
        onChange(info) {
            // const reader = new FileReader();
            // const binary =reader.readAsText(info.fileList[0]);
            console.log(info);
            console.log(info.file, info.fileList[0]);
            setFiles(info);
            const file = info.fileList[0];
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                return false
            }
            setFiles(info.fileList);
        },
        onDrop(e) {
            // setFiles(e.dataTransfer.files[0]);
            const file = e.dataTransfer.files[0];
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                return false
            }
            setFiles(e.dataTransfer.files)
        },
    };
    const beforeUpload = (file) => {
        // console.log(file[0]);
        const reader = new FileReader();
        // const binary =reader.readAsText(file);
        // console.log(binary);
        // setFiles(file);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            form.setFieldsValue({ "floorImage": null });
            // setFiles(null);
        }
        return isJpgOrPng;
    };









    return (
        <Fragment>
            <Header />
            <DashboardContextProvider>
                <DashboardHeader />
                <div className="createform">

                    <div className="floor-form">
                        <Form className="formfloor"
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                            colon={false}
                            layout="horizondal">
                            <Row>
                                <Col className="txt-label" >
                                    <h4>Floor Name</h4>
                                </Col>
                                <Col md={10}>
                                    <Form.Item label="" name="floorName" rules={[{ required: true, message: "Enter the Floor name" }]}>
                                        <Input className="floor-uniq" placeholder="Enter the Floor name" values={listData?.floorName} maxLength={40}></Input>
                                    </Form.Item>
                                </Col>
                                <Col md={6} className="floor-image">
                                    <Button type="primary" htmlType="submit" block>

                                        <SaveOutlined />
                                        Save</Button>
                                </Col>
                            </Row>
                            <hr />

                            {/* `${localStorage.getItem("BASE_URL")}/plc-config/image?name=${data.data.floorImageUrl}` */}
                            <Row>
                                <Col className="txt-label" >
                                    <h4>Floor Image</h4>
                                </Col>
                                <Col md={10}>
                                    <Form.Item label="" name="floorImage" rules={[{ required: false, message: "Floor Image is required" }]}>
                                        <Col className="floor-image" md={24} >
                                            <Row>
                                                <Col className="img-possition" md={8} xs={24}>
                                                    {files === null ?
                                                        <img src={`${BASE_URL}/floor/image?floorId=${listData?.floorId}`} className="size_image" /> :
                                                        <img
                                                            src={URL.createObjectURL(files[0]?.originFileObj)}
                                                            alt=".."
                                                            className="size_image"
                                                        />
                                                    }
                                                    {/* <Image name="new1.png" className="size_image" /> */}
                                                    <Upload {...uploadProps} showUploadList={false} maxCount={1} beforeUpload={beforeUpload}>
                                                        <div className="img">

                                                            <h3><SyncOutlined />  Upload</h3><p>PNG, JPG (max 1400x650px) </p>
                                                            {/* <p>or </p><p>(16:9) </p> */}
                                                        </div>
                                                    </Upload>
                                                    {/* {files === null ?
                                                        editData.state !== null && editData?.state.floorImageUrl !== null ?
                                                          
                                                            ""
                                                            : */}
                                                    {/* <Image name="new1.png" className="size_image" /> */}
                                                    {/* : null
                                                    } */}
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <hr />
                            {/* <Row className="save">
                                <Col className="txt-label">
                                </Col>
                                <Col  md={7} xs={24}>
                                   
                                </Col>
                            </Row> */}

                        </Form>

                    </div>

                </div>

            </DashboardContextProvider>
        </Fragment>
    )
}
export default FormFloorPlan;