import Header from "../header/Header";
import DashboardHeader from "./components/DashboardHeader";
import DashboardItems from "./components/DashboardItems";
import { DashboardContextProvider } from "./providers/DashboardProvider";
import { useNavigate } from "react-router";
import "./dashboard.css";
import { useEffect } from "react";
import {
  redirectIfDepartmentIsNotSet,
  validateAndSetBaseURL,
} from "../common/utils";

export default function DashboardPage() {
  let navigate = useNavigate();
  useEffect(() => {
    redirectIfDepartmentIsNotSet();
    validateAndSetBaseURL();
    navigate("/environmental_condition_monitoring")
  }, []);

  return (
    <div>
      <Header />
      <DashboardContextProvider>
        <DashboardHeader />
        <DashboardItems />
      </DashboardContextProvider>
    </div>
  );
}