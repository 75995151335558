import MetricsHeader from "../components/MetricsHeader";
// import LineGraph from "../components/LineGraph";
import LineGraphFilter from "../components/LineGraphFilter";


const common = () => {
    return (
        <>
            <MetricsHeader />
            <LineGraphFilter />
            {/* <LineGraph /> */}
        </>
    )
}
export default common;