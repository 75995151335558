import React, { useContext, useEffect } from "react";
import { Row, Col } from "antd";
import csvDownload from "json-to-csv-export";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { MetricsContext } from "../providers/MetricsProvider";
import GraphData from "../models/GraphData";
import {
  getDiffernceOfDateInHours,
  roundToNearestHour,
  trimMillisFromTimeStamp,
} from "../../common/utils";

export default function LineGraph() {

  const metricsContext = useContext(MetricsContext);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
  ) as any;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: '#fff'
        }
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label: string = context.dataset.label || "";

            if (label.includes("Warning"))
              return `${label} : ${context.parsed.y}`;

            if (label) {
              label += ": ";
            }

            let currentSensor: GraphData = metricsContext.sensorData.filter(
              (data) =>
                trimMillisFromTimeStamp(
                  data.time,
                  metricsContext.lineChartInterval
                ) === context.label
            )[0];

            let product = "";
            let machineStatus = "";
            if (context.parsed.y !== null) {
              label += Number(context.parsed.y).toFixed(2);
              product = `Product : ${currentSensor.product || "-"} `;
              // machineStatus = `Machine Status : ${currentSensor.currentMachineStatus || "-"}`;
              machineStatus = `Machine Status: ${currentSensor.machineStatus || "-"} `;
            }
            return [label, product, machineStatus];
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.2)', // Light color (white) with alpha for transparency
          borderDash: [3, 3], // Dotted line
          display: true,
        },
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: 24,
          color: "#fff",
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#fff",
          callback: function (value: any, index: any, ticks: any) {
            return (value.toFixed(2)) + " " + metricsContext.currentActiveSensor.unit;
          },
        },
      },
    },
  } as any;

  // const getLabels = () => {
  //   if (metricsContext.sensorData.length === 0) return [];
  //   let currentHour = trimMillisFromTimeStamp(
  //     metricsContext?.sensorData[0]?.time,
  //     ""
  //   );
  //   let labels: string[] = [];
  //   metricsContext.sensorData.forEach((data, index) => {
  //     if (getDiffernceOfDateInHours(currentHour, data.time) > 2) {
  //       currentHour = trimMillisFromTimeStamp(data.time, "");
  //       labels.push(
  //         trimMillisFromTimeStamp(
  //           roundToNearestHour(data.time),
  //           metricsContext.lineChartInterval
  //         )
  //       );
  //     } else {
  //       labels.push(
  //         trimMillisFromTimeStamp(data.time, metricsContext.lineChartInterval)
  //       );
  //       // labels.push();
  //     }
  //   });
  //   return labels;
  // };

  const labels = metricsContext.sensorData.map((data) =>
    trimMillisFromTimeStamp(data.time, metricsContext.lineChartInterval)
  );

  const data = {
    labels,
    datasets: [
      {
        data: metricsContext.sensorData.map((data) => data.sensorValue),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
        label: metricsContext.currentActiveSensor.description,
      },
      {
        data: metricsContext.sensorData.map(
          (data) => metricsContext.currentActiveSensor.upperWarningLimit
        ),
        label: "Upper Warning Limit",
        backgroundColor: "#FA8C75",
        borderColor: "#FA8C75",
        pointRadius: 0,
        borderDash: [10, 5],
      },
      {
        data: metricsContext.sensorData.map(
          (data) => metricsContext.currentActiveSensor.lowerWarningLimit
        ),
        label: "Lower Warning Limit",
        backgroundColor: "#FA8C75",
        borderColor: "#FA8C75",
        pointRadius: 0,
        borderDash: [10, 5],
      },
      {
        data: metricsContext.sensorData.map(
          (data) => metricsContext.currentActiveSensor.sensorUpperRange
        ),
        label: "Sensor Upper Limit",
        backgroundColor: "red",
        borderColor: "red",
        pointRadius: 0,
      },
      {
        data: metricsContext.sensorData.map(
          (data) => metricsContext.currentActiveSensor.sensorLowerRange
        ),
        label: "Sensor Lower Limit",
        backgroundColor: "red",
        borderColor: "red",
        pointRadius: 0,
      },
    ],
  };

  const dataToConvert = {
    data: metricsContext.sensorData,
    filename: "Single Sensor Data",
    delimiter: ",",
    headers: [
      "Time",
      "Sensor Value",
      "Product",
      "Machine Status",
      "Sensor Id",
    ],
  };




  return (
    <div>
      <button onClick={() => csvDownload(dataToConvert)} className="csv-d">
        Export CSV
      </button>
      <div className="graph">
        <Row gutter={[16, 16]}>
          <Col md={1} className="txt-center">
            <h1 className="tile">
              {metricsContext.currentActiveSensor.legendText}{" "}
            </h1>
          </Col>
          <Col md={22}>
            <Line options={options} data={data} height="25" width="100" />

          </Col>
          <Col md={24}>
            <h4 style={{ textAlign: "center" }}>Date</h4>
          </Col>
        </Row>
      </div>
    </div>
  );
}
