import MultiLineGraphFilter from "./MultiLineGraphFilter";
// import MultiSensorGraph from "./MultiSensorGraph";

export default function MultiGraphGroup() {
  return (
    <>
      <MultiLineGraphFilter />
      {/* <MultiSensorGraph />   */}
    </>
  );
}
