import React, { Fragment, useContext } from 'react';
import { Row, Col } from "antd";
import { MetricsContext } from "../metrics/providers/MetricsProvider";
import ReactApexChart from 'react-apexcharts';
import csvDownload from "json-to-csv-export";
// import "./graphstyle.css";








const WhiskerPlot = () => {
  const metricsContext = useContext(MetricsContext);


// if(metricsContext?.sensorData.length>0){
  const alldata = metricsContext?.sensorData && metricsContext?.sensorData?.map((item) => ({
    x: item?.yearMonth,
    y: [
      item?.minimum,
      item?.firstQuartileQ1,
      item?.median,
      item?.thirdQuartileQ2,
      item?.maximum,
    ],
  }));
// }
console.log(alldata);
// var alldata = metricsContext?.sensorData && metricsContext?.sensorData?.map((item) => {
  
//   const formattedValues = [
//     String(item?.minimum?.toFixed(3)),
//     String(item?.firstQuartileQ1?.toFixed(3)),
//     String(item?.median?.toFixed(3)),
//     String(item?.thirdQuartileQ2?.toFixed(3)),
//     String(item?.maximum?.toFixed(3)),
//   ];

//   console.log(formattedValues);

//   return {
//     x: item.yearMonth,
//     y: formattedValues,
//   };
// });


  const series = [
    {
      type: 'boxPlot',
      data:
        alldata

    }
  ]
  const options1 = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const yValues = w.config.series[seriesIndex].data[dataPointIndex].y;

        // Format each value in the yValues array with three digits without rounding off
        const formattedValues = yValues.map((value) =>
          typeof value === 'number' ? value.toFixed(3) : value
        );
  
    return '<div class="apexcharts-tooltip-box apexcharts-tooltip-boxPlot">'+
     '<div>Minimum: <span class="value">'+formattedValues[0]+'</span></div><div>'+
     'Q1: <span class="value">'+formattedValues[1]+'</span></div><div>'+
     'Median: <span class="value">'+formattedValues[2]+'</span></div><div>'+
     'Q3: <span class="value">'+formattedValues[3]+'</span></div><div>'+
     'Maximum: <span class="value">'+formattedValues[4]+'</span></div></div>'
    
      },
    },
    chart: {
      type: 'boxPlot',
      height: 350

    },
    stroke: {
      colors: ['#ffffff']
    },
    toolbar: {
      show: false, // Hide the entire toolbar
    },
    zoom: {
      enabled: false, // Disable zooming
    },
    // plotOptions: {
    //   boxPlot: {
    //     colors: {
    //       upper: '#5C4742',
    //       lower: '#A5978B'
    //     }
    //   }
    // },
    grid: {
      show: true,
      borderColor: 'rgba(255, 255, 255, 0.6)',
      strokeDashArray: [3, 3],
      position: 'back', // Draw behind the chart
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          // Format the y-axis labels to display three digits without rounding off
          return value.toFixed(3);
        },
      },
    },
  }

  const dataToConvert = {
    data: metricsContext.sensorData,
    filename: "Single Sensor Data",
    delimiter: ",",
    headers: [
      "First Quartile Q1",
      "Maximum",
      "Mean",
      "Median",
      "Minimum",
      "Third Quartile Q2",
      "Year Month",
    ],
  };


  console.log(series);

  return (
    <Fragment>
      <div>
        <button onClick={() => csvDownload(dataToConvert)} className="csv-d">
          Export CSV
        </button>
        <div className="graph">
          <Row gutter={[16, 16]}>
            <Col md={1} className="txt-center">
              <h1 className="tile">
                {/* {metricsContext.currentActiveSensor.legendText}{" "} */}
              </h1>
            </Col>
            <Col md={22}>

              <ReactApexChart options={options1} series={series.length > 0 ? series : [{ x: '', y: [] }]} type="boxPlot" height={350} />

            </Col>
            <Col md={24}>
              <h4 style={{ textAlign: "center" }}>Months</h4>
            </Col>
          </Row>
        </div>
      </div>



    </Fragment>
  );
};

export default WhiskerPlot;


