import React, { useEffect, useState } from "react";
import PlcService from "../services/PlcService";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons"
import { getAllMachines } from "../../dasboard/services/DashboardService";
import PlcConfig from "../../dasboard/models/PlcConfig";

export default function CreateSensorForm() {
  const [description, setDescription] = useState("");
  const [sensorTypeId, setSensorTypeId] = useState("");
  const [plcId, setPlcId] = useState("");
  const [sensorNumber, setSensorNumber] = useState("");
  const [captureTime, setCaptureTime] = useState("");
  const [upperLimit, setUpperLimit] = useState("");
  const [upperWarningLimit, setUpperWarningLimit] = useState("");
  const [lowerLimit, setLowerLimit] = useState("");
  const [lowerWarningLimit, setLowerWarningLimit] = useState("");
  const [legendText, setLegendText] = useState("");
  const [legendTextShort, setLegendTextShort] = useState("");
  const [unit, setUnit] = useState("");
  const [sensorLowerRange, setSensorLowerRange] = useState("");
  const [sensorUpperRange, setSensorUpperRange] = useState("");
  const [factor, setFactor] = useState("");

  const [machineList, setMachineList] = useState([] as any);

  async function prepPayLoadAndCreateSensor() {
    let requestPayLoad = {
      description,
      sensorTypeId,
      plcId,
      sensorNumber,
      captureTime,
      upperLimit,
      upperWarningLimit,
      lowerLimit,
      lowerWarningLimit,
      legendText,
      legendTextShort,
      unit,
      sensorLowerRange,
      sensorUpperRange,
      factor,
    };
    let isSensorCreated: boolean = await PlcService.createSensor(
      requestPayLoad
    );
    if (isSensorCreated) {
      alert("PLC Config Created Successfully");
      document.location.href = "/";
    }
  }

  useEffect(() => {
    getAllMachines().then((machines) => {
      setMachineList(machines);
      setPlcId(String(machines[0].plcId));
    });
  }, []); // eslint-disable-line

  return (
    <Row>
      <Col md={2} />
      <Col md={20} xs={24}>
        <form className="create-plc-form sub-form" >
          <Row gutter={[16, 16]}>
            <Col md={24}>
              <h1><Link to="/environmental_condition_monitoring"><ArrowLeftOutlined /></Link> &nbsp;&nbsp; Add New Sensor</h1>
            </Col>
            <Col md={5} xs={12}>
              <p className="form-label"> Description</p>
              <input
                type="text"
                placeholder=" 20u CF"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
            <Col md={5} xs={12}>
              <p className="form-label"> Sensort Type Id</p>
              <input
                type="number"
                placeholder=" 1"
                value={sensorTypeId}
                onChange={(e) => setSensorTypeId(e.target.value)}
              />
            </Col>
            <Col md={5} xs={12}>
              <p className="form-label">Select PLC</p>
              <select
                value={plcId}
                onChange={(e) => {
                  setPlcId(e.target.value);
                  console.log(e.target.value);
                }}
              >
                {machineList.map((machine: PlcConfig) => {
                  return (
                    <option value={machine.plcId} key={machine.plcId}>
                      {machine.description}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col md={5} xs={12}>
              <p className="form-label"> Legend Text</p>
              <input
                type="text"
                placeholder=" OIL"
                value={legendText}
                onChange={(e) => setLegendText(e.target.value)}
              />
            </Col>
            <Col md={4} xs={12}>
              <p className="form-label"> Legend Text Short</p>
              <input
                type="text"
                placeholder=" OIL"
                value={legendTextShort}
                onChange={(e) => setLegendTextShort(e.target.value)}
              />
            </Col>
            <Col md={3} xs={12}>
              <p className="form-label"> Unit</p>
              <input
                type="text"
                placeholder=" DB"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              />
            </Col>
            <Col md={3} xs={12}>
              <p className="form-label"> Capture time</p>
              <input
                type="number"
                placeholder=" 60"
                value={captureTime}
                onChange={(e) => setCaptureTime(e.target.value)}
              />
            </Col>
            <Col md={3} xs={12}>
              <p className="form-label"> Upper Limit</p>
              <input
                type="number"
                placeholder=" 100"
                value={upperLimit}
                onChange={(e) => setUpperLimit(e.target.value)}
              />
            </Col>
            <Col md={3} xs={12}>
              <p className="form-label"> Sensor Number</p>
              <input
                type="number"
                placeholder=" 10"
                value={sensorNumber}
                onChange={(e) => setSensorNumber(e.target.value)}
              />
            </Col>
            <Col md={3} xs={12}>
              <p className="form-label"> Upper Warning Limit</p>
              <input
                type="number"
                placeholder=" 100"
                value={upperWarningLimit}
                onChange={(e) => setUpperWarningLimit(e.target.value)}
              />
            </Col>
            <Col md={3} xs={12}>
              <p className="form-label"> Lower Limit</p>
              <input
                type="number"
                placeholder=" 100"
                value={lowerLimit}
                onChange={(e) => setLowerLimit(e.target.value)}
              />
            </Col>
            <Col md={3} xs={12}>
              <p className="form-label"> Lower Warning Limit</p>
              <input
                type="number"
                placeholder=" 100"
                value={lowerWarningLimit}
                onChange={(e) => setLowerWarningLimit(e.target.value)}
              />
            </Col>

            <Col md={3} xs={12}>
              <p className="form-label"> Sensor Lower Range</p>
              <input
                type="number"
                placeholder=" 0"
                value={sensorLowerRange}
                onChange={(e) => setSensorLowerRange(e.target.value)}
              />
            </Col>
            <Col md={5} xs={12}>
              <p className="form-label"> Sensor Upper Range</p>
              <input
                type="number"
                placeholder=" 100"
                value={sensorUpperRange}
                onChange={(e) => setSensorUpperRange(e.target.value)}
              />
            </Col>
            <Col md={4} xs={12} />
            <Col md={14} xs={12} />
            <Col md={3} xs={12}>
              <Link to="/environmental_condition_monitoring">
                <div
                  className="btn btn-primarys space"
                >
                  Cancel
                </div>
              </Link>
            </Col>
            <Col md={3} xs={12}>
              <div
                className="btn btn-primary space"
                onClick={() => prepPayLoadAndCreateSensor()}
              >
                Create Sensor
              </div>
            </Col>
            <Col md={3} xs={12} />
          </Row>
        </form>
      </Col>
      <Col md={2} />
    </Row>
  );
}
