import React, { Fragment, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, List, Modal, Pagination, Row, Spin, message } from 'antd';
import { Link } from 'react-router-dom';
import { PlusSquareOutlined } from '@ant-design/icons';
import { DashboardContextProvider } from '../../../dasboard/providers/DashboardProvider';
import ColorPicker from 'react-pick-color';
import Header from '../../../header/Header';
import Image from '../../Image'
import "./style.css";
import "../configstyle.css";
import DashboardHeader from '../../../dasboard/components/DashboardHeader';



const BASE_URL = localStorage.getItem("BASE_URL");
const ConfigureMap = () => {
    const [form] = Form.useForm();
    const [listStation, setlistStation] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [listData, setListData] = useState<any>([]);
    const [oneFloordata, setoneFloordata] = useState<any>([]);
    const [Loading, setLoading] = useState<any>(false);
    const [showModel, setshowModel] = useState<any>();
    const [ColorState, setColorState] = useState<any>([]);
    const [color, setColor] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const payloadfloor = { "pageNumber": pageNumber, "pageSize": 10 }
        setLoading(true)
        //to get all floor list data
        fetch(`${BASE_URL}/plc-config/getFloor`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify(payloadfloor)
        })
            .then(response => response.json())
            .then(data => {
                if(data.status===200){
                    setListData(data.data)
                    setLoading(false)
                }
                else{
                    // message.error(data.data)
                    setListData(data.data)
                    setLoading(false)
                }
                
                
            });
    }, [refresh])


    const showModal = (open: any, alldata?: any, state?: any, color?: any) => {
        setoneFloordata(alldata)
        setColor(color)
        setColorState(state)
        setshowModel(open)
        setIsModalOpen(true);
        form.resetFields();
        //to show the Station list data
        fetch(`${BASE_URL}/plc-config/dropdown-station/v3?floorId=${alldata?.floorId}`)
            .then(response => response.json())
            .then(data => {
                setlistStation(data.data)
            });

    };


    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    //close modal
    //colorpicker


    const onFinish = (values: any) => {
        setLoading(true)
        setRefresh(true)
        fetch(`${BASE_URL}/plc-config/removeStation?stationId=${values.gatewayMapIds}&floorId=${oneFloordata.floorId}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(data => {
                setIsModalOpen(false);
                setLoading(false)
                setRefresh(false)
            });
    }

    const OnChangeColor = (values: any) => {
        const payload = {
            "floorId": oneFloordata.floorId,
            [ColorState]: color
        }
        setRefresh(true)
        setLoading(true)
        fetch(`${BASE_URL}/plc-config/mapFloorColor`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                setRefresh(false)
                setIsModalOpen(false);
                setLoading(false)
            });

    }
    const onChange = (page: any) => {
        setPageNumber(page - 1);
    }



    return (
        <Fragment>
            <Header />
            <DashboardContextProvider>
                <DashboardHeader />
                <div className="config-head">

                    <div className="txt-right">
                        <Link to="/FormFloorPlan">
                            <Button>
                                <PlusSquareOutlined />
                                New Floor</Button>
                        </Link>
                    </div>
                </div>
                {Loading === true ?
                    <Spin style={{ height: "80vh" }} tip="Loading...." size="large">
                        <div className="content" />
                    </Spin>
                    :
                    <Fragment>
                        {listData.content === null || listData.content === undefined ?
                              <div className="empty-data">
                                                    <Image name={"empty.png"} className={"emptyimage"} />
                                                     <p>{listData}</p>
                                                </div> 
                            :
                            <Fragment>
                                   <div className="common-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Floor Name</th>
                                        <th>Floor Topview</th>
                                        <th>Station Details</th>
                                        <th>Station Mapping</th>
                                        <th>Colors</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { listData.content?.length === 0 ? (
                                        <tr>
                                            <td colSpan={6} className='empty'>
                                            <h4 style={{ textAlign: "center",color: "red" }}>Floor not found</h4>
                                               
                                            </td>
                                        </tr>
                                    ) :
                                        listData.content.map((item: any, a: any) => (
                                            <tr>
                                                <td>{item?.floorName}</td>

                                                <td>
                                                    <img src={`${BASE_URL}/floor/image?floorId=${item.floorId}`} className="floorImg" />
                                                </td>
                                                <td className='under-line'>
                                                    <a onClick={() => showModal("station", item)}> {`${item?.totalStations} Station`}</a>

                                                </td>
                                                <td>
                                                    <Link to={`/floormap`} state={item.floorId}>
                                                        <Image name="Frame 26.png" className={undefined} />                                            </Link>
                                                    <a onClick={() => showModal("delete", item)}>   <Image name="delete.png" className={undefined} /></a>

                                                </td>
                                                <td>
                                                    <div className='colortr'>
                                                        <div className='colormodel' onClick={() => showModal("color", item, "lowerColor", item.lowerColor)}>
                                                            <div style={{ backgroundColor: `${item.lowerColor}` }} className='max'></div>
                                                            <label>Warning <br /> Limit</label>
                                                        </div>
                                                        <div className='colormodel' onClick={() => showModal("color", item, "normalColor", item.normalColor)}>
                                                            <div style={{ backgroundColor: `${item.normalColor}` }} className='avg'></div>
                                                            <label>Normal </label>
                                                        </div>
                                                        <div className='colormodel' onClick={() => showModal("color", item, "upperColor", item.upperColor)}>
                                                            <div style={{ backgroundColor: `${item.upperColor}` }} className='min'></div>
                                                            <label>Upper & Lower <br /> Limit</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>

                                                    <Link to={`/FormFloorPlan`} state={item.floorId} >
                                                        <Image name="edit.png" className={undefined} />
                                                    </Link>

                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="table-pagination">

                            <Pagination total={listData?.totalElements} current={pageNumber + 1} onChange={onChange} />
                        </div>
                            </Fragment>
                         
                        }
                      
                    </Fragment>
                }

                <Modal title="" width={showModel === "color" ? 300 : 350} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    {showModel === "station" ?
                        <Fragment>
                            <div className="common-table station">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Station Name</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Loading ?
                                            <div className="dashboard-loading">
                                                <Spin tip="Loading...." size="large">
                                                    <div className="content" />
                                                </Spin>
                                            </div>
                                            : listStation === null || listStation === undefined || listStation?.length === 0 ? (
                                                <div className="loading">
                                                    <h4 style={{ textAlign: "center", color: "red" }}>Station not found</h4>
                                                </div>
                                            ) : listStation?.map((item: any, a: any) => (
                                                <tr key={a}>

                                                    <td>{item.stationName}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </Fragment> : null}
                    {showModel === "color" ? <div className='model'>
                        <Form onFinish={OnChangeColor}>
                            <Form.Item
                                name="colorpicker"
                                rules={[{ required: false, message: 'Please input your colorpicker!' }]}>

                                <ColorPicker color={color} onChange={(color) => setColor(color.hex)} />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 1, span: 16 }} className='changebutton'>
                                <Button type="primary" htmlType="submit" loading={Loading}>
                                    Change
                                </Button>
                            </Form.Item>

                        </Form>
                    </div> : null}
                    {showModel === "delete" ?
                        <Form
                            form={form}
                            onFinish={onFinish}
                            name="gateway-unlink"
                            layout="vertical">
                            <Form.Item name="gatewayMapIds">
                                <Checkbox.Group className='checkbox-style'>
                                    <List itemLayout="horizontal" dataSource={listStation}
                                        renderItem={(item: any) => (
                                            <Row gutter={[16, 16]} className='check'>
                                                <Col md={3}>
                                                    <Checkbox value={item?.stationId} />
                                                </Col>
                                                <Col md={21}>
                                                    <h3>{item?.stationName}</h3>
                                                </Col>
                                            </Row>
                                        )} />
                                </Checkbox.Group>
                            </Form.Item>
                            <Row gutter={[16, 16]} className='unlink-button'>
                                <Col md={listStation?.length === 0 ? 24 : 12} xs={listStation?.length === 0 ? 24 : 12}>
                                    <Form.Item>
                                        <Button className="no-btn" onClick={handleCancel} type="primary" block>Cancel</Button>
                                    </Form.Item>
                                </Col>
                                <Col md={listStation?.length === 0 ? 0 : 12} xs={listStation?.length === 0 ? 0 : 12}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={Loading} block >Unlink</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form> : null}
                </Modal>
            </DashboardContextProvider>
        </Fragment >
    )
}

export default ConfigureMap;
