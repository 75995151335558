import React, { useContext } from "react";
import { Row, Col } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { MetricsContext } from "../providers/MetricsProvider";
import { trimMillisFromTimeStamp } from "../../common/utils";

export default function MultiSensorGraph() {
  const metricsContext = useContext(MetricsContext);

  const getToolTipFooter = (tooltipItems: any) => {
    let metadata: any = tooltipItems[0];
    console.log(metadata);
    if (metadata.dataset.label.includes("Limit")) {
      return;
    }

    let toolTipData = {} as any;
    metricsContext.multiGraphData[metadata.datasetIndex].graphDataList.forEach(
      (graphData: any, index: number) => {
        if (index === metadata.dataIndex) {
          toolTipData = graphData;
        }
      }
    );

    let product = `Product : ${toolTipData.productName || "-"} `;
    let machineStatus = `Machine Status : ${toolTipData.machineStatus || "-"} `;
    return [product, machineStatus];
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
  ) as any;

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          footer: getToolTipFooter,
        },
      },
      legend: {
        display: true,
        labels: {
          color: '#fff'
        }
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: 24,
          color: "#fff",
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#fff",
        }
      },
    },
  } as any;

  function getRandomColor() {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const getDataPointLabel = (sensorId: number) => {
    let label = "";
    metricsContext.machineSensorList.forEach((sensor) => {
      if (sensor.sensorId === sensorId) {
        label = sensor.description;
      }
    });
    return label;
  };

  const getDataSet = () => {
    let finalDataset: any = [];
    let tempDataPoint = {} as any;
    metricsContext.multiGraphData.map((data: any) => {
      tempDataPoint = {
        data: data.graphDataList.map((sensor: any) => sensor.sensorValue),
        fill: false,
        spanGaps: false,
        borderColor: getRandomColor(),
        tension: 0.1,
        label: getDataPointLabel(data.sensorId),
      };
      finalDataset.push(tempDataPoint);
    });
    if (metricsContext.multiGraphSensorLimits.length !== 0) {
      console.log(metricsContext.multiGraphSelectedSensors);
      metricsContext.multiGraphSensorLimits.map((sensorLimit: any) => {
        if (
          metricsContext.multiGraphSelectedSensors.includes(
            sensorLimit.sensorId
          )
        ) {
          finalDataset.push({
            data: getLables().map((a: any) => sensorLimit.upperLevel),
            label: sensorLimit.sensorName + " Sensor Upper Limit",
            backgroundColor: "red",
            borderColor: "red",
            pointRadius: 0,
          });

          finalDataset.push({
            data: getLables().map((a: any) => sensorLimit.lowerLevel),
            label: sensorLimit.sensorName + " Sensor Lower Limit",
            backgroundColor: "red",
            borderColor: "red",
            pointRadius: 0,
          });

          finalDataset.push({
            data: getLables().map((a: any) => sensorLimit.lowerWarningLevel),
            label: sensorLimit.sensorName + " Lower Warning Limit",
            backgroundColor: "#FA8C75",
            borderColor: "#FA8C75",
            pointRadius: 0,
            borderDash: [10, 5],
          });

          finalDataset.push({
            data: getLables().map((a: any) => sensorLimit.upperWarningLevel),
            label: sensorLimit.sensorName + " Upper Warning Limit",
            backgroundColor: "#FA8C75",
            borderColor: "#FA8C75",
            pointRadius: 0,
            borderDash: [10, 5],
          });
        }
      });
    }
    return finalDataset;
  };

  const getLables = () => {
    let a: string[] = [];
    if (metricsContext.multiGraphData[0] === undefined) return a;
    metricsContext.multiGraphData[0].graphDataList.map((dp: any) => {
      a.push(trimMillisFromTimeStamp(dp.time, ""));
    });
    return a;
  };

  const data = {
    labels: getLables(),
    datasets: getDataSet(),
  };

  return (
    <div>
      <div className="graph">
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Line options={options} data={data} height="25" width="100" />
          </Col>
          <Col md={24}>
            <h4 style={{ textAlign: "center" }}>Date & Time</h4>
          </Col>
        </Row>
      </div>
    </div>
  );
}
