import React, { Fragment, useState } from "react";
import { Row, Col } from 'antd';
import Img from '../common/Image';
import "./Style.css";

const Imagenew = ({ name, className, onClick = null }) => {


    var btn = document.getElementById('1');
    var box = document.getElementById('2');



    const [isHovered, setIsHovered] = useState(false)
    const handleMouseOver = () => {
        setIsHovered(true);

    }




    const [xVal, setXval] = useState(0);
    const [yVal, setYval] = useState(0);
    return (
        <Fragment>
            <div className={className} onClick={onClick} alt="..." onImageClick={({ x, y }) => {
                setXval(x)
                setYval(y)
                // console.log(x,y,"inside");
            }}>
                <div className="App">
                    <div id="1" className="size" onMouseOver={handleMouseOver} onMouseOut={() => setIsHovered(false)}>
                        <Row style={{ height: "100%" }} className="hoverdummy">
                            <div style={{ width: "40px", maxWidth: "30%" }}>

                                <div className="iconside">

                                    <Img name="temp.png" className="img-size" />
                                </div>

                            </div>
                            <div style={{ width: "70%", maxWidth: "70%" }}>
                                <div className="contentbox">
                                    <h3>76.1F</h3>
                                    {isHovered ? <p > pump current status
                                        12/06/2023 4;45pm
                                    </p> : null}
                                </div>

                            </div>
                        </Row>
                    </div>

                </div>
            </div>
        </Fragment>
    )
};

export default Imagenew;