import { Fragment, useEffect, useRef, useState } from "react";
import Header from "../header/Header";
import DashboardHeader from "../dasboard/components/DashboardHeader";
import { Button, Checkbox, Col, Form, Modal, Pagination, Popover, Row, Select, Spin, Table, Tabs, message } from 'antd';
import { DashboardContextProvider } from "../dasboard/providers/DashboardProvider";
import { SyncOutlined, EditOutlined } from "@ant-design/icons";
import ImageMarker from '@madup-inc/react-imagemarker'
import { Link, useLocation } from "react-router-dom";
import '@madup-inc/react-imagemarker/dist/index.css'
import { SaveOutlined } from '@ant-design/icons';
import Image from '../common/Image'
import Imagenew from './Imagenew'
import { ColumnsType } from "antd/lib/table";

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const BASE_URL = localStorage.getItem("BASE_URL");

export default function FloorImage2() {
  const columns: ColumnsType<any> = [
    {
      title: 'Sensor Name',
      dataIndex: 'sensorName',
      key: 'sensorName',
      render: (text, record) => ({
        props: {
          // style: { color: record.fontColor },
        },
        children: <Link
        to={`/metrics/over_view/${record.plcId}?n=${record.description
          }&s=${record.machineStatus}&productName=${record.productName || "-"
          }&db=/environmental_condition_monitoring&status=${record.statusDescription}`} state={record.sensorId}
      ><div>{text}</div></Link>,
      }),
    },
    {
      title: 'Sensor Value',
      dataIndex: 'sensorValue',
      key: 'sensorValue',
      render: (text, record) => ({
        props: {
          style: { color: record.fontColor },
        },
        children: 
       <div> 
        {/* <Link
       to={`/metrics/over_view/${record.plcId}?n=${record.description
         }&s=${record.machineStatus}&productName=${record.productName || "-"
         }&db=${document.location.pathname}&status=${record.statusDescription}`} state={record.sensorId}
     > */}
      {parseFloat(text).toFixed(2)}
     {/* </Link> */}
     </div>
      ,
      }),
    },

  ];
  const paramsfloorId = useLocation();
  const [form] = Form.useForm();
  const [pagenationdata, setpagenationdata] = useState<any>();
  const [floorId, setfloorId] = useState<any>();
  const [dropDownOptions, setdropDownOptions] = useState<any>([]);
  let [markerId, setMarkerId] = useState(0);
  const [xVal, setXval] = useState(0);
  const [yVal, setYval] = useState(0);
  const [pagenumber, setpagenumber] = useState(0);
  const [Loading, setLoading] = useState<any>(false);
  const [coordinates, setcoordinates] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonstate, setButtonstate] = useState(false);
  const [dropDownValue, setdropDownValue] = useState("Overall Sensor by Station");
  const [gatewayDropdownData, setgatewayDropdownData] = useState<any>([]);
  const [floorList, setfloorList] = useState<any>([]);

  const [mark, setMark] = useState(coordinates?.map((item: any, e: any) => {
    return {
      id: e + 1,
      x: item?.xcoordinate,
      y: item?.ycoordinate,
      render: () => dropDownValue === "hgfh" ?
        <div className='dote1' style={{ backgroundColor: "red" }} >

        </div>
        :
        <div className="marker-style"><Imagenew
          name=""
          className="content"
        /></div>
    }
  }));

  const handleOnChange = (val: any) => {
    setdropDownValue(val)
  }

  const onFinish = (values: any) => {
    setLoading(true)
    const payload = {
      "floorId": paramsfloorId.state,
      "stationId": values.station,
      "point1": xVal,
      "point2": yVal
    }
    fetch(`${BASE_URL}/plc-config/mapStation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        // message.success('Data Mapped Successfully',1)  
        setLoading(false)
        setIsModalOpen(false)
        form.resetFields();
      });


  };
  const onChange = (page: any) => {
    // initValues.pageNumber = page - 1;
    // setInitValues(initValues);
    setpagenumber(page - 1);
  }


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnEdit = () => {
    setButtonstate(!buttonstate)
    // if (buttonstate) { }
  }

  const nextpage = (value: any) => {


    // setpagenumber(pagenumber+1)
    // console.log(pagenumber);
    setdropDownValue("Overall Sensor by Station")
    setfloorId(value)
    fetch(`${BASE_URL}/plc-config/getStations?sensorGroup=Overall Sensor by Station&floorId=${value}`)
      .then(response => response.json())
      .then(data => {
        setcoordinates(data.data)
      }, err => {
        console.log(err);

      });

  }

  useEffect(() => {
    const payloadfloor = {
      "pageNumber": 0,
      "pageSize": 10
    }
    //   setLoading(true)
    //to get all floor list data
    fetch(`${BASE_URL}/plc-config/getFloor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payloadfloor)
    })
      .then(response => response.json())
      .then(data => {

        console.log(data);
        setfloorList(data.data.content)
        // setLoading(false)
      });



  }, [])



  useEffect(() => {

    // fetch(`${BASE_URL}/plc-config/dropdown-station`)
    if (paramsfloorId.state !== null) {
      fetch(`${BASE_URL}/plc-config/dropdown-station/v4?floorId=${paramsfloorId.state}`)
        .then(response => response.json())
        .then(data => {
          setgatewayDropdownData(data.data)
        });
    }


    fetch(`${BASE_URL}/plc-config/getGroups`)
      .then(response => response.json())
      .then(data => {
        setdropDownOptions(data.data)
      });
  }, [Loading])

  useEffect(() => {
    console.log(paramsfloorId.state);

    if (paramsfloorId.state !== null) {
      fetch(`${BASE_URL}/plc-config/getStations?sensorGroup=${dropDownValue === undefined ? "Overall Sensor by Station" : dropDownValue}&floorId=${paramsfloorId.state}`)
        .then(response => response.json())
        .then(data => {
          setcoordinates(data.data)
        }, err => {
          console.log(err);

        });
    }
    else {
      const payloaddata = {
        "pageSize": 1,
        "pageNumber": pagenumber,
        "sensorGroup": dropDownValue === undefined ? "Overall Sensor by Station" : dropDownValue.toString()
      }
      fetch(`${BASE_URL}/plc-config/getStationsV2`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payloaddata)
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 200) {
            setpagenationdata(data.data)
            setfloorId(data.data.content[0].floorId)
            setcoordinates(data.data.content[0].stationListResDtoList)
          }
          else {

          }
        }, err => {
          console.log(err);

        });
    }
  }, [Loading, pagenumber])

  useEffect(() => {
    if (floorId) {
      fetch(`${BASE_URL}/plc-config/getStations?sensorGroup=${dropDownValue === undefined ? "Overall Sensor by Station" : dropDownValue}&floorId=${floorId}`)
        .then(response => response.json())
        .then(data => {
          setcoordinates(data.data)
        }, err => {
          console.log(err);

        });
    }
    else if (paramsfloorId.state) {
      fetch(`${BASE_URL}/plc-config/getStations?sensorGroup=${dropDownValue === undefined ? "Overall Sensor by Station" : dropDownValue}&floorId=${paramsfloorId.state}`)
        .then(response => response.json())
        .then(data => {
          setcoordinates(data.data)
        }, err => {
          console.log(err);

        });
    }
  }, [dropDownValue])


  useEffect(() => {
    setMark(coordinates?.map((item: any, e: any) => {
      const handleDragEnd = () => {
        console.log('Drag operation ended!');
      };
      return {
        id: e + 1,
        x: item?.xcoordinate,
        y: item?.ycoordinate,
        render: () =>
          <div className="dote1" style={{ backgroundColor: item?.heatMapStatus }}
            draggable
            onDragEnd={handleDragEnd} >
            {buttonstate ? null : <Popover className="insidetable" content={
              <Fragment>
                <div className="card">
                  <div className="machine">
                    <h2 className="station">{item.stationName}</h2>
                    <h2>Machine ID</h2>
                    <p className="machine-id">
                      {
                        item?.machineId === null ? null : item?.machineId.split(',').map((str: any, index: any) => (
                          <li key={index}>{str}</li>
                        ))
                      }

                    </p>
                  </div>
                  <Table className="hover-table hover" style={{ color: "green" }} columns={columns} dataSource={item?.sensorList} pagination={{ pageSize: 7, hideOnSinglePage: true }} />
                </div>
              </Fragment>
            }
              placement="rightTop" >
              <div className="content1" style={{ backgroundColor: item?.heatMapStatus }} >
                <span style={{ fontSize: 20 }}></span>
              </div>
            </Popover>}</div>
      }
    }));
  }, [coordinates, Loading, buttonstate])



  return (
    <Fragment>
      <Header />
      <DashboardContextProvider>
        <DashboardHeader />
        {Loading ?



          <div className="dashboard-loading">
            <Spin tip="Loading...." size="large">
              <div className="content" />
            </Spin>
          </div> :
          <Fragment>
            {floorList ?
              <Row className="image-mark">
                <Col md={20}>
                  {paramsfloorId.state == null ?
                    <Tabs tabPosition={'left'} defaultActiveKey={floorList[0]?.floorId} onChange={nextpage}>
                      {
                        floorList?.map((item: any, e: any) => (
                          <Tabs.TabPane tab={item?.floorName} key={item?.floorId}  >
                            <div className="img-card" >
                              <div className="dropdown">
                                <Select placeholder="Select Group" value={dropDownValue} defaultValue={dropDownValue} showArrow={true} onChange={handleOnChange} style={{ width: 250 }} >
                                  {dropDownOptions?.map((item: any, a: any) => (
                                    <Option value={item} key={a}>{item}</Option>
                                  ))}
                                </Select>
                                {localStorage.getItem("ROLE") === "admin" ?
                                  <Fragment>{paramsfloorId.state == null ? null : <button onClick={handleOnEdit}>{buttonstate ? <SyncOutlined /> : <EditOutlined className="icon" />}</button>}
                                  </Fragment> : null}
                              </div>
                              <ImageMarker
                                src={paramsfloorId.state === null ? `${BASE_URL}/floor/image?floorId=${floorId}` : `${BASE_URL}/floor/image?floorId=${paramsfloorId?.state}`}
                                markers={mark}
                                onImageClick={({ x, y }) => {
                                  if (buttonstate) {
                                    setXval(x)
                                    setYval(y)
                                    showModal()
                                  }
                                  if (markerId != 0) {
                                    // handleMarkerDrag(x, y)

                                  }
                                }}
                              />
                            </div>
                          </Tabs.TabPane>
                        ))
                      }
                    </Tabs> :


                    <div className="img-card" >
                      <div className="dropdown">
                        <Select placeholder="Select Group" defaultValue={dropDownValue} showArrow={true} onChange={handleOnChange} style={{ width: 250 }} >
                          {dropDownOptions?.map((item: any, a: any) => (
                            <Option value={item} key={a}>{item}</Option>
                          ))}
                        </Select>
                        {localStorage.getItem("ROLE") === "admin" ?
                          <Fragment>{paramsfloorId.state == null ? null : <button onClick={handleOnEdit}>{buttonstate ? <SyncOutlined /> : <EditOutlined className="icon" />}</button>}
                          </Fragment> : null}
                      </div>
                      <ImageMarker
                        src={paramsfloorId.state === null ? `${BASE_URL}/floor/image?floorId=${floorId}` : `${BASE_URL}/floor/image?floorId=${paramsfloorId?.state}`}
                        markers={mark}
                        onImageClick={({ x, y }) => {
                          if (buttonstate) {
                            console.log(x, y);
                            setXval(x)
                            setYval(y)
                            showModal()
                          }
                        }}
                      />
                    </div>
                  }
                  {/* {paramsfloorId.state == null ? <Col md={24} className="pagination">
       {pagenationdata?.content?.length === 0 ? null : <Pagination showSizeChanger={false} onChange={onChange} defaultPageSize={1} current={pagenumber + 1} total={pagenationdata?.totalElements} />}
    
    
     </Col> : null
     } */}
                </Col>
              </Row> :
              <div className="empty-data">
                <Image name={"empty.png"} className={"emptyimage"} />
                {/* <img src="empty.png" alt="Empty Icon"/> */}
                <p>No data available</p>
              </div>
            }


          </Fragment>

        }



        {/* Model Dialog Box */}
        <Modal className="mapping" title="" width={280} open={isModalOpen} centered onOk={handleOk} onCancel={handleCancel}>
          <div className='model-buttons'>
            <Form className="mapform"
              form={form}
              onFinish={onFinish}
              layout="vertical">
              <Form.Item label="Choose Station" name="station" rules={[{ required: true, message: "Station is required!" }]}>
                <Select suffixIcon={<Image name="arrowdown.png" className={undefined} />} placeholder="Select Status">
                  {gatewayDropdownData?.map((item: any, e: any) => (
                    <Option key={e} value={item?.stationId}>{item?.stationName}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit"  >
                  <SaveOutlined />
                  Map Station</Button>
              </Form.Item>

            </Form>
          </div>
        </Modal>
        {/* End Model Dialog Box */}
      </DashboardContextProvider>
    </Fragment>
  );
}