import moment from "moment";
import React, { createContext, useState } from "react";
import MetricsContextType from "../../types/MetricsContextType";
import SensorData from "../models/SensorData";
import GraphData from "../models/GraphData";

export const MetricsContext = createContext<MetricsContextType>(
  {} as MetricsContextType
);

export const MetricsContextProvider = (props: any) => {
  const [machineSensorList, setMachineSensorList] = useState([]);
  const [sensorData, setSensorData] = useState([] as any);
  const [currentActiveSensor, setCurrentActiveSensor] = useState(
    {} as SensorData
  );
  
  const [lineChartFilterDate, setLineChartFilterDate] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm")
  );
  //default
  const [lineChartInterval, setLineChartInterval] = useState("DAY" as string);

  const [multiGraphData, setMultiGraphData] = useState([] as GraphData[][]);

  const [multiGraphSensorLimits, setMultiGraphSensorLimits] = useState(
    [] as any
  );

  const [multiGraphSelectedSensors, setMultiGraphSelectedSensors] = useState(
    [] as any
  );

  return (
    <MetricsContext.Provider
      value={{
        machineSensorList,
        setMachineSensorList,
        sensorData,
        setSensorData,
        currentActiveSensor,
        setCurrentActiveSensor,
        lineChartFilterDate,
        setLineChartFilterDate,
        lineChartInterval,
        setLineChartInterval,
        multiGraphData,
        setMultiGraphData,
        multiGraphSensorLimits,
        setMultiGraphSensorLimits,
        multiGraphSelectedSensors,
        setMultiGraphSelectedSensors,
      }}
    >
      {props.children}
    </MetricsContext.Provider>
  );
};
