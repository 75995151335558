import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Fragment } from "react";
import { Row, Col } from "antd";

export default function MetricsHeader() {
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [machineName, setMachineName] = useState("");
  const [machineStatus, setMachineStatus] = useState("");
  useEffect(() => {
    setMachineName(searchParams.get("n") || "");
    setMachineStatus(searchParams.get("s") || "");
  }, []); // eslint-disable-line

  var url_string = window.location.href;
  var url = new URL(url_string);
  var status = url.searchParams.get("status");

  return (
    <Fragment>
      <Row className="head-part">
        <Col md={4} xs={20}>
          <h1>{machineName}</h1>
        </Col>
        <Col md={20} xs={4}>
          <div
            className={
              "metrics-plc-status " +
              (status === "CONNECTED" ? "pale-green-btn" : "red-btn")
            }
          ></div>
        </Col>
      </Row>
    </Fragment>
  );
}
