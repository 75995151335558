import SensorGauge from "./SensorGauge";
import { useContext, useEffect, useState } from "react";
import { MetricsContext } from "../providers/MetricsProvider";
import { Fragment } from "react";
import { Row, Col, Spin } from "antd";
import { getAllSensorsWithPlcId } from "../services/MetricsService";
import { useParams } from "react-router-dom";

export default function GaugeList() {
  const params = useParams();
  const plcId: number = params.plcId === undefined ? 0 : Number(params.plcId);

  const [sensorList, setSensorList] = useState([]) as any;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    getAllSensorsWithPlcId(plcId).then((data) => {
      setSensorList(data);
      setLoading(true);
    });
  }, []);

  var url_string = window.location.href;
  var url = new URL(url_string);
  var p = url.searchParams.get("productName");
  var sk = url.searchParams.get("s");

  return (
    <Fragment>
      {loading ? (
        <Row gutter={[16, 16]} className="over-view">
          <Col md={24} xs={24}>
            <h1 className="product-names">
              Product Name : {p || "-"} &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp; Machine
              Status : {sk || "-"}
            </h1>
            <br></br>
          </Col>
          {sensorList.length > 0 &&
            sensorList.map((sensorData: any, index: any) => {
              return (
                <Col className="txt-center">
                  <SensorGauge key={index} data={sensorData} />
                </Col>
              );
            })}
        </Row>
      ) : (
        <div className="dashboard-loading">
          <Spin tip="Loading...." size="large">
            <div className="content" />
          </Spin>
        </div>
      )}
    </Fragment>
  );
}


{/* <Col md={4} xs={12} className="one txt-center"></Col> */ }