import { Console, time } from "console";
import constants from "./constants";
import moment from "moment";

const redirectToUrl = (path: string) => {
  document.location.href = path;
};

const getJsonHeader = () => {
  let header = new Headers();
  header.append("Content-Type", "application/json");
  return header;
};

const redirectIfDepartmentIsNotSet = () => {
  try {
    let params: URLSearchParams = new URL(document.location.href).searchParams;
    const base64EncondedDepartment: string = params.get("dept_name") as string;

    if (base64EncondedDepartment !== null) {

      localStorage.setItem("DEPARTMENT", atob(base64EncondedDepartment));
      window.location.href = window.location.pathname;
    }
    else if (base64EncondedDepartment === null) {
      localStorage.setItem("DEPARTMENT", "");
      window.location.href = window.location.pathname;
    }

    if (constants.DEPARTMENT === null && constants.DEPARTMENT === undefined) {
      window.location.replace("/404");
    }
  } catch (e: any) {
    console.log(e);
    window.location.replace("/404");
  }
};

const validateAndSetBaseURL = () => {
  localStorage.setItem("BASE_URL", "https://shimanoapi.corpfield.com");
};

const validateAndSetBaseURL8085 = () => {
  localStorage.setItem("BASE_URL", "https://shimanoapi.corpfield.com");
};
// https://shimanoapi.corpfield.com
// http://172.30.8.27:8086
const trimMillisFromTimeStamp = (
  timestamp: string,
  interval: string
): string => {
  if (timestamp.length < 20) return timestamp;

  return timestamp.slice(0, -6).slice(-1) === ":"
    ? timestamp.slice(0, -7)
    : timestamp.slice(0, -6);
};

const stripMachineNameFromSensorName = (
  machineName: string,
  sensorName: string
): string => {
  return sensorName.replace(machineName, "");
};

const getColorForGauge = (
  upperLimit: number,
  upperWarningLimit: number,
  lowerWarningLimit: number
): any => {
  let colorsList = [] as String[];
  let red = "#FF0000";
  let green = "#00FF00";

  let redMin: number = Math.round((lowerWarningLimit / upperLimit) * 40);
  let redMax: number = Math.round((upperWarningLimit / upperLimit) * 40);

  if (redMin < 0) redMin = 0;

  for (let i = 0; i < redMin; i++) {
    colorsList.push(red);
  }

  for (let i = redMin; i < redMax; i++) {
    colorsList.push(green);
  }

  for (let i = redMax; i < 40; i++) {
    colorsList.push(red);
  }

  return colorsList;
};

const getDiffernceOfDateInDays = (fromDate: string, toDate: string): number => {
  var startDate = moment(fromDate, "YYYY-MM-DD HH:mm");
  var endDate = moment(toDate, "YYYY-MM-DD HH:mm");

  let result = Number(endDate.diff(startDate, "days"));
  return result;
};

const getDiffernceOfDateInSeconds = (fromDate: string, toDate: string): number => {
  var startDate = moment(fromDate, "YYYY-MM-DD HH:mm");
  var endDate = moment(toDate, "YYYY-MM-DD HH:mm");

  let result = Number(endDate.diff(startDate, "seconds"));
  return result;
};

const getDiffernceOfDateInHours = (
  fromDate: string,
  toDate: string
): number => {
  var startDate = moment(fromDate, "YYYY-MM-DD HH:mm");
  var endDate = moment(toDate, "YYYY-MM-DD HH:mm");

  return moment.duration(endDate.diff(startDate)).asHours();
};

const roundToNearestHour = (date: string): string => {
  return moment(date, "YYYY-MM-DD HH:mm")
    .startOf("hour")
    .format("YYYY-MM-DD HH:mm")
    .toString();
};

export {
  redirectToUrl,
  getJsonHeader,
  redirectIfDepartmentIsNotSet,
  validateAndSetBaseURL,
  validateAndSetBaseURL8085,
  trimMillisFromTimeStamp,
  stripMachineNameFromSensorName,
  getColorForGauge,
  getDiffernceOfDateInDays,
  getDiffernceOfDateInHours,
  roundToNearestHour,
  getDiffernceOfDateInSeconds
  
};
