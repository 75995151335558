import { getJsonHeader } from "../../common/utils";
import PlcConfig from "../model/PlcConfig";
import SensorCreateModel from "../model/SensorCreateModel";

const BASE_URL = localStorage.getItem("BASE_URL");

async function createPLC(requesPayLoad: PlcConfig): Promise<boolean> {
  let response = await fetch(`${BASE_URL}/plc-config`, {
    headers: getJsonHeader(),
    method: "POST",
    body: JSON.stringify(requesPayLoad),
  });
  return true;
}

async function createSensor(
  requesPayLoad: SensorCreateModel
): Promise<boolean> {
  let response = await fetch(`${BASE_URL}/sensor`, {
    headers: getJsonHeader(),
    method: "POST",
    body: JSON.stringify(requesPayLoad),
  });
  return true;
}

export default {
  createPLC,
  createSensor,
};
