import React, { useContext, useState, useEffect } from "react";
import { MetricsContext } from "../providers/MetricsProvider";
import {
  Row,
  Col,
  Spin,
  DatePicker,
  Select,
  SelectProps,
  Checkbox,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import moment from "moment";
import csvDownload from "json-to-csv-export";
import { Fragment } from "react";
import { getDiffernceOfDateInDays } from "../../common/utils";
import { useParams } from "react-router-dom";
import MultiSensorGraph from "./MultiSensorGraph";
import {
  getAllSensorsWithPlcId,
  getMultiSensorDataFilteredWithCustomDate,
} from "../services/MetricsService";

const { RangePicker } = DatePicker;

export default function MultiLineGraphFilter() {
  const { plcId } = useParams();
  const params = useParams();
  const changeDateFormat = (date: any) =>
    moment(date).format("YYYY-MM-DD HH:mm");

  const metrixContext = useContext(MetricsContext);

  const [selectedSensorList, setSelectedSensorList] = useState([] as number[]);
  const [fromDate, setFromDate] = useState("" as string);
  const [toDate, setToDate] = useState("" as string);
  const [hide, setHide] = useState(true as Boolean);
  const [dateDifference, setDateDifference] = useState(0 as number);
  const [loading, setLoading] = useState(true as any);

  useEffect(() => {
    const plcId: number = params.plcId === undefined ? 0 : Number(params.plcId);
    getAllSensorsWithPlcId(plcId).then((data) => {
      metrixContext.setMachineSensorList(data);
    });
  }, []); // eslint-disable-line

  const disabledDate = (current: any) =>
    current && current > moment().endOf("day");

  const onChangeFilter = async (dateString: any) => {
    let fromDate: string = changeDateFormat(dateString[0]._d);
    let toDate: string = changeDateFormat(dateString[1]._d);
    let dateDifferenceLocal: number = getDiffernceOfDateInDays(
      fromDate,
      toDate
    );
    setToDate(toDate);
    setFromDate(fromDate);
    await setDateDifference(dateDifferenceLocal);

    getMultiSensorDataFilteredWithCustomDate(
      selectedSensorList,
      toDate,
      fromDate,
      dateDifference
    ).then((data) => {
      metrixContext.setMultiGraphData(data);
    });
    setHide(false);
  };

  const sensorListForSelectBox: SelectProps["options"] = [];
  metrixContext.machineSensorList.forEach((sensor) => {
    sensorListForSelectBox.push({
      label: sensor.description,
      value: sensor.sensorId,
    });
  });

  const updateSelectedSensorList = (selectedSensorList: any) => {
    setLoading(false);
    setSelectedSensorList(selectedSensorList);
    getMultiSensorDataFilteredWithCustomDate(
      selectedSensorList,
      toDate,
      fromDate,
      dateDifference
    ).then((data) => {
      metrixContext.setMultiGraphData(data);
      metrixContext.setMultiGraphSelectedSensors(selectedSensorList);
      setLoading(true);
    });
  };

  const BASE_URL = localStorage.getItem("BASE_URL");
  const [csvData, setCsvData] = useState<any>();
  const asyncPostCall = async () => {
    const response = await fetch(`${BASE_URL}/plc-config/multiSensor/csv`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sensorIdList: selectedSensorList,
        fromDate: fromDate,
        toDate: toDate,
      
      }),
    });
  
    const data: any = await response.text();

    setCsvData(data);
  };
  function downloadCSV(dataToConvert: any): void {
const data=dataToConvert.data;
const filename=dataToConvert.filename;
    const csvData = new Blob([data], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
    URL.revokeObjectURL(csvURL);
  }


  useEffect(() => {
    if (selectedSensorList.length !== 0) {
      asyncPostCall();
    }
  }, [selectedSensorList]);


  const dataToConvert: any = {
    data: csvData,
    filename: "Multi Sensor Data",
    delimiter: ",",
 
  };

  const [check, setCheck] = useState(false) as any;
  const [limit, setLimit] = useState([]) as any;

  const onChange = (e: CheckboxChangeEvent) => {
    setCheck(e.target.checked);
  };

  useEffect(() => {
    if (check !== null) {
      fetch(`${BASE_URL}/plc-config/sensor/sensorLimits?plcId=${plcId}`)
        .then((response) => response.json())
        .then((data) => {
          setLimit(data.data);
          if (check) {
            metrixContext.setMultiGraphSensorLimits(data.data);
          } else {
            metrixContext.setMultiGraphSensorLimits([]);
          }
        });
    }
  }, [check]);

  return (
    <Fragment>
      <br></br>
      <br></br>
      <Row className="over-view" gutter={[16, 16]}>
        <Col md={16} xs={7} className="txt-right">
          <Checkbox defaultChecked={check} onChange={onChange}>
            Show Limits
          </Checkbox>
          <RangePicker
            showTime
            onChange={onChangeFilter}
            disabledDate={disabledDate}
          />
        </Col>
        <Col md={6} xs={7}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please Select Sensor"
            onChange={updateSelectedSensorList}
            options={sensorListForSelectBox}
            disabled={hide === true ? true : false}
          />
        </Col>
        <Col md={2} xs={7}>
          {selectedSensorList.length === 0 ? (
            <button className="csv-de">Export CSV</button>
          ) : (
            <button
              onClick={() => downloadCSV(dataToConvert)}
              className="csv-de"
            >
              Export CSV
            </button>
          )}
        </Col>
      </Row>
      {loading ? (
        <MultiSensorGraph />
      ) : (
        <div className="dashboard-loading">
          <Spin tip="Loading...." size="large">
            <div className="content" />
          </Spin>
        </div>
      )}

      <br></br>
      {check ? (
        <>
          {limit?.map((common: any, a: any) => (
            <Row className="limit-status" key={a}>
              <Col md={2} />
              <Col md={4}>
                <h1>
                  Sensor Name : <span>{common?.sensorName}</span>
                </h1>
              </Col>
              <Col md={4}>
                <h1>
                  Lower Limit : <span>{common?.lowerLevel}</span>
                </h1>
              </Col>
              <Col md={4}>
                <h1>
                  Lower Warning Limit : <span>{common?.lowerWarningLevel}</span>
                </h1>
              </Col>
              <Col md={4}>
                <h1>
                  Upper Limit : <span>{common?.upperLevel}</span>
                </h1>
              </Col>
              <Col md={4}>
                <h1>
                  Upper Warning Limit : <span>{common?.upperWarningLevel}</span>
                </h1>
              </Col>
              <Col md={2} />
            </Row>
          ))}
        </>
      ) : null}
      <br></br>
    </Fragment>
  );
}
