import { redirectToUrl, stripMachineNameFromSensorName, } from "../../common/utils";
import { Row, Col, Card, Button, Tooltip, Popover, Tag, Space } from "antd";
import PlcConfigSensor from "../models/PlcConfigSensor";
import { Fragment, Key, useState } from "react";
import { Link } from "react-router-dom";
import Table, { ColumnsType } from "antd/lib/table";



const BASE_URL = localStorage.getItem("BASE_URL");
export default function DashboardItem(props?: any) {

  const [isSensorViewToggled, setSensorViewToggle] = useState(false);


  const columns: ColumnsType<any> = [
    {
      title: 'Production Area',
      dataIndex: 'productionArea',
      key: 'productionArea',
      // render: (text : any) => <a>{text}</a>,
    },
    {
      title: 'Machine Id',
      dataIndex: 'machineId',
      key: 'machineId',
    },

  ];
  const [data, setData] = useState();
  // const data: any[] = [
  //   {
  //     key: '1',
  //     productionArea: 'SPL-MS1',
  //     machineId: "MSCNCL01"
  //   },
  //   {
  //     key: '2',
  //     productionArea: 'SPL-MS2',
  //     machineId: "MSCNCL02"
  //   },
  //   {
  //     key: '3',
  //     productionArea: 'SPL-MS3',
  //     machineId: "MSCNCL03"
  //   },

  //   {
  //     key: '4',
  //     productionArea: 'SPL-MS4',
  //     machineId: "MSCNCL04"
  //   },
  //   {
  //     key: '5',
  //     productionArea: 'SPL-MS5',
  //     machineId: "MSCNCL05"
  //   }
  // ];
  const handleMouseEnter = (id: any) => {
    console.log(id);
    fetch(`${BASE_URL}/plc-config/getTableDetails?plcId=${id}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.data !== null) {
          setData(data.data)
        }
        else {
          // setData([])
        }

      });

  };
  const content = (
    <Fragment>
      <Table className="common-table hover" columns={columns} dataSource={data} pagination={{ pageSize: 7, hideOnSinglePage: true }} />
    </Fragment>
  );
  return (
    <Card className={props?.data?.sensorData !== null ? "card-start" : "card-start card-hide"}>
      <Row gutter={[8, 8]}>


        <Link
          to={`/metrics/current_sensor_stats/${props.data.plcId}?n=${props.data.description
            }&s=${props.data.machineStatus}&productName=${props.data.productName || "-"}&db=${document.location.pathname}&status=${props.data.statusDescription}`}
        >




          <Col md={22} xs={24} className="txt-center">
            <p className="light-red" onMouseEnter={() => handleMouseEnter(props.data.plcId)}>
              {
                document.location.pathname ===
                  "/environmental_condition_monitoring" ?
                  <Popover className="insidetable" content={content} placement="rightTop" >
                    <span style={{ fontSize: 20 }}>{props.data.description}</span>  </Popover> : <span style={{ fontSize: 20 }}>{props.data.description}</span>
              }

              {document.location.pathname ===
                "/environmental_condition_monitoring"
                ? <h5 className={props.data.statusDescription === "CONNECTED" ? "warning-btn-green" : "warning-btn-red"}>
                  {props.data.statusDescription === "CONNECTED"
                    ? "EnvCM PLC Connected"
                    : "EnvCM PLC Disconnected"
                  }
                </h5>
                :
                <h5 className={props.data.statusDescription === "CONNECTED" ? "warning-btn-green" : "warning-btn-red"}>
                  {props.data.statusDescription === "CONNECTED"
                    ? "MCM's PLC Connected"
                    : "MCM's PLC Disconnected"
                  }
                </h5>
              }



              {document.location.pathname ===
                "/environmental_condition_monitoring"
                ? <Tooltip
                  title={
                    props.data.statusDescription === "CONNECTED"
                      ? "EnvCM PLC Connected"
                      : "EnvCM PLC Disconnected"
                  }
                  color={
                    props.data.statusDescription === "CONNECTED" ? "green" : "red"
                  }
                >
                  <span
                    className={
                      props.data.statusDescription === "CONNECTED"
                        ? "light-success"
                        : "light-danger"
                    }
                  ></span>
                </Tooltip>
                :
                <Tooltip
                  title={
                    props.data.statusDescription === "CONNECTED"
                      ? "MCM's PLC Connected"
                      : "MCM's PLC Disconnected"
                  }
                  color={
                    props.data.statusDescription === "CONNECTED" ? "green" : "red"
                  }
                >
                  <span
                    className={
                      props.data.statusDescription === "CONNECTED"
                        ? "light-success"
                        : "light-danger"
                    }
                  ></span>
                </Tooltip>
              }









            </p>
            <h3 className="id-range">
              Product Name : {props.data.productName || "-"}
            </h3>
            <h3 className="id-range">
              Machine Status :
              {props.data.machineStatus || "-"}
            </h3>
          </Col>
          <Col md={24} xs={24} className="txt-center">
            <div className="new-img">
              <img
                className="dashboard-item-image"
                src={`${localStorage.getItem(
                  "BASE_URL"
                )}/plc-config/image?name=${props.data.description}.gif`}
                alt={props.data.description}
              />
            </div>
          </Col>
          <Col md={0} xs={0}>
            <Button
              className={
                (props.data.statusId === 0 ? "green-btn" : "red-btn") +
                " dashboard-item-footer-item"
              }
            >
              {props.data.statusId === 0 ? "Connected" : "Disconnected"}
            </Button>
          </Col>
          <Col md={24} xs={24}>
            <Link
              to={`/metrics/current_sensor_stats/${props.data.plcId}?n=${props.data.description
                }&s=${props.data.machineStatus}&productName=${props.data.productName || "-"
                }&db=${document.location.pathname}&status=${props.data.statusDescription}`}
            >
              <Button
                className="blue-btn"
              >
                View Metrics
              </Button>
            </Link>
          </Col>
        </Link>

        {props?.data?.sensorData
          ?.slice(0, 2)
          .map((data: PlcConfigSensor, index: any) => {
            return (
              <Col flex={4} xs={24} key={index} className="txt-center txt-bold">
                <Button
                  className={
                    data.upperLimit < data.currentValue || data.currentValue < data.lowerLimit
                      ? "pale-red"
                      : data.upperWarningLimit > data.currentValue && data.currentValue > data.lowerWarningLimit
                        ? "pale-green" : "pale-yellow"

                    // data.currentValue >= data.lowerWarningLimit && data.currentValue <= data.upperWarningLimit
                    //   ? "pale-green"
                    //   : "pale-red"
                  }
                  onClick={() =>
                    redirectToUrl(
                      `/metrics/over_view/${props.data.plcId}?n=${props.data.description}&s=${props.data.machineStatus}&currentActiveSnsor=${data.sensorId}&db=${document.location.pathname}&status=${props.data.statusDescription}`
                    )
                  }
                >
                  <span
                    className={
                      data.currentValue >= data.lowerWarningLimit && data.currentValue <= data.upperWarningLimit
                        ? "dashboard-sensor-txt-success"
                        : "dashboard-sensor-txt-danger"
                    }
                  >
                    {stripMachineNameFromSensorName(
                      props.data.description,
                      data.description
                    ) + " : "}
                  </span>

                  <span style={{ marginRight: 5 }}></span>
                  <span
                    className={
                      data.currentValue >= data.lowerWarningLimit && data.currentValue <= data.upperWarningLimit
                        ? "dashboard-sensor-txt-success"
                        : "dashboard-sensor-txt-danger"
                    }
                  >
                    {String(data.currentValue.toFixed(2))}
                  </span>
                </Button>
              </Col>
            );
          })}
        {/* <Link to={'/boxchart'}>
  <Button className="green-btn"  

                      >
              3D floor map
              </Button></Link> */}
        {!isSensorViewToggled && props?.data?.sensorData?.length > 2 && (
          <Button
            className="view-btn"
            onClick={() => setSensorViewToggle(true)}
          >
            View All
          </Button>
        )}

        {isSensorViewToggled &&
          props?.data?.sensorData?.length > 2 &&
          props?.data?.sensorData
            ?.slice(2, props?.data?.sensorData?.length)
            .map((data: PlcConfigSensor, index: any) => {
              return (
                <Col
                  flex={4}
                  xs={24}
                  key={index}
                  className="txt-center txt-bold"
                >
                  <Button
                    className={
                      data.upperLimit < data.currentValue || data.currentValue < data.lowerLimit
                        ? "pale-red"
                        : data.upperWarningLimit > data.currentValue && data.currentValue > data.lowerWarningLimit
                          ? "pale-green" : "pale-yellow"

                      // data.currentValue >= data.lowerWarningLevel && data.currentValue <= data.upperWarningLimit
                      //   ? "pale-green"
                      //   : "pale-red"
                    }
                    onClick={() =>
                      redirectToUrl(
                        `/metrics/over_view/${props.data.plcId}?n=${props.data.description}&s=${props.data.machineStatus}&currentActiveSnsor=${data.sensorId}&db=${document.location.pathname}&status=${props.data.statusDescription}`
                      )
                    }
                  >
                    <span
                      className={
                        data.currentValue >= data.lowerWarningLimit && data.currentValue <= data.upperWarningLimit
                          ? "dashboard-sensor-txt-success"
                          : "dashboard-sensor-txt-danger"
                      }
                    >
                      {stripMachineNameFromSensorName(
                        props.data.description,
                        data.description
                      ) + " : "}
                    </span>

                    <span style={{ marginRight: 5 }}></span>
                    <span
                      className={
                        data.currentValue >= data.lowerWarningLimit && data.currentValue <= data.upperWarningLimit
                          ? "dashboard-sensor-txt-success"
                          : "dashboard-sensor-txt-danger"
                      }
                    >
                      {String(data.currentValue.toFixed(2))}
                    </span>
                  </Button>
                </Col>
              );
            })}
        {isSensorViewToggled && (
          <Button
            className="view-btn"
            onClick={() => setSensorViewToggle(false)}
          >
            View Less
          </Button>
        )}
      </Row>
      <div className="no-sensor">
        {props?.data?.sensorData !== null ? null : <h2>No Sensor Available</h2>}
      </div>
    </ Card>
  );
}
