import React, { useContext, useEffect, Fragment, useState } from "react";
import DashboardItem from "./DashboardItem";
import { Row, Col, Spin } from "antd";
import { DashboardContext } from "../providers/DashboardProvider";
import PlcConfig from "../models/PlcConfig";
import { useSearchParams } from "react-router-dom";

export default function DashboardItems(props:any) {
  const {loading, machineList} = props;
  const dashboardContext = useContext(DashboardContext);
  let params: URLSearchParams = new URL(document.location.href).searchParams;
  const base64EncondedDepartment: string = params.get("dept_name") as string;
 
  useEffect(() => {
    dashboardContext.setMachineList(machineList);
  },[machineList]);

  return (
    <Fragment>
      {loading ?
        <Row gutter={[32, 32]} className="dashboard-card">
          {dashboardContext?.machineList !== null &&
            dashboardContext?.machineList?.map(
              (machine: PlcConfig, index: number) => {
                return (
                  <Col md={6} xs={12} key={index}>
                    <DashboardItem data={machine} />
                  </Col>
                );
              }
            )}
        </Row>
        :
        <div className="dashboard-loading">
          <Spin tip="Loading...." size="large">
            <div className="content" />
          </Spin>
        </div>}
    </Fragment>
  );
}
