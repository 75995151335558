import Header from "../header/Header";
import GaugeList from "./components/GaugeList";
import LineGraph from "./components/LineGraph";
import LineGraphFilter from "./components/LineGraphFilter";
import MetricsHeader from "./components/MetricsHeader";
import Common from "./components/common";
import { Fragment } from "react";
import { Link, useParams,useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Row, Col, Tabs } from "antd";
import "./metrics.css";
import { MetricsContextProvider } from "./providers/MetricsProvider";
import MultiGraphGroup from "./components/MultiGraphGroup";
// import MultiLineGraph from "./components/MultiLineGraph";
// import MultiLineGraphFilter from "./components/MultiLineGraphFilter";

export default function MetricsPage() {
  const { plcId } = useParams();
  var sensorId=useLocation()
  var url_string = window.location.href;
  var url = new URL(url_string);
  var s = url.searchParams.get("s");
  var n = url.searchParams.get("n");
  var p = url.searchParams.get("productName");
  var db = url.searchParams.get("db");
  var status = url.searchParams.get("status");
 
 if(sensorId.state){
  localStorage.setItem("SensorId",sensorId.state );
 }
 else{
  localStorage.removeItem("SensorId");
 }
  return (
    <Fragment>
      <Header />
      <MetricsContextProvider>
        <div>
          <div className="common-head">
            <p onClick={() => window.history.back()} className="arg">
              <ArrowLeftOutlined className="back" />
            </p>
            <Link
              className={
                window.location.pathname === `/metrics/over_view/${plcId}`
                  ? "active"
                  : "inactive"
              }
              to={`/metrics/over_view/${plcId}?n=${n}&s=${s}&productName=${p}&db=${db}&status=${status}`}
            >
              Over View
            </Link>
            <Link
              className={
                window.location.pathname ===
                  `/metrics/current_sensor_stats/${plcId}`
                  ? "active"
                  : "inactive"
              }
              to={`/metrics/current_sensor_stats/${plcId}?n=${n}&s=${s}&productName=${p}&db=${db}&status=${status}`}
            >
              Current Sensor Status
            </Link>

            <Link
              className={
                window.location.pathname ===
                  `/metrics/multi_sensor_stats/${plcId}`
                  ? "active"
                  : "inactive"
              }
              to={`/metrics/multi_sensor_stats/${plcId}?n=${n}&s=${s}&productName=${p}&db=${db}&status=${status}`}
            >
              Multi Sensor Status
            </Link>
          </div>
          <div>
            {window.location.pathname === `/metrics/over_view/${plcId}` ? (
              <Common />
            ) : null}

            {window.location.pathname ===
              `/metrics/current_sensor_stats/${plcId}` ? (
              <GaugeList />
            ) : null}

            {window.location.pathname ===
              `/metrics/multi_sensor_stats/${plcId}` ? (
              <MultiGraphGroup />
            ) : null}
          </div>
        </div>
      </MetricsContextProvider>
    </Fragment >
  );
}
