import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import DashboardPage from "./app-components/dasboard/DashboardPage";
import Common from "./app-components/metrics/components/common";
import MetricsPage from "./app-components/metrics/MetricsPage";
import CreatePlcPage from "./app-components/plc/CreatePlcPage";
import CreateSensorPage from "./app-components/plc/CreateSensortPage";
import DataBaseOne from "./app-components/dasboard/clone/db-1";
import DataBaseTwo from "./app-components/dasboard/clone/db-2";
import FloorImage from "./app-components/floorMap/floorImage";
import Whiskerpoints from "./app-components/whiskerGraph/points";
import FloorImage2 from "./app-components/floormap2/floorImagenew";
import ConfigureMap from "./app-components/common/phase2/configureMap/configureMap";
import FormFloorPlan from "./app-components/common/phase2/configureMap/mapping/form";
import DataBasethree from "./app-components/dasboard/clone/db-3";
// import BoxChart from "./app-components/metrics/components/BoxGraph";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/environmental_condition_monitoring" element={<DataBaseOne />} />
        <Route path="/machine_condition_monitoring" element={<DataBaseTwo />} />
        
        <Route path="/floormap" element={<DataBasethree />} />

        <Route path="/create-plc" element={<CreatePlcPage />} />
        <Route path="/create-sensor" element={<CreateSensorPage />} />
        <Route path="/metrics/over_view/:plcId" element={<MetricsPage />} />
        <Route path="/configuremap" element={<ConfigureMap />} />
        {/* <Route path="/floormap1" element={<FloorImage2 />} /> */}
        {/* <Route path="/boxchart" element={<Whiskerpoints />} /> */}
        <Route path="/FormFloorPlan" element={<FormFloorPlan />} />
        <Route
          path="/metrics/current_sensor_stats/:plcId"
          element={<MetricsPage />}
        />
        <Route
          path="/metrics/multi_sensor_stats/:plcId"
          element={<MetricsPage />}
        />
        <Route
          path="/404"
          element={<h1>The resouce you are looking for is not avaliable</h1>}
        />
        <Route path='*' element={<DashboardPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
