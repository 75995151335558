import constants from "../../common/constants";
import PlcConfig from "../models/PlcConfig";

const BASE_URL = localStorage.getItem("BASE_URL");

const getAllMachines = async (props?: any): Promise<PlcConfig[]> => {
  try {

    const response = await (
      await fetch(`${BASE_URL}/plc-config?department=${props || constants.DEPARTMENT}`)
    ).json();
    const machineList: PlcConfig[] = response.data;
    console.log(machineList);
    return machineList;
  }

  catch (error) {
    console.error(error);
    return [];
  }
};

export { getAllMachines };
