import { Link } from "react-router-dom";
import Image from '../../common/Image'
import { useNavigate } from 'react-router';
import { Fragment, useState, useEffect, useRef } from "react";
import { CalendarOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Select, SelectProps } from "antd";
import { getAllMachines } from "../services/DashboardService";

const moment = require("moment");

export default function DashboardHeader(props: any) {

  const { setValue } = props;

  const [dynamicStyles, setCondition] = useState("");
  const [form] = Form.useForm();
  const Ref = useRef();

  const handleChange = (value: string[]) => {
    setValue(value);
  };

  const options: SelectProps['options'] = [
    { label: "CF", value: "CF" },
    { label: "STP", value: "STP" },
    { label: "MS", value: "MS" },
    { label: "HT", value: "HT" },
    { label: "MF", value: "MF" },
    { label: "TC", value: "TC" },
    { label: "EE", value: "EE" },
    { label: "QA", value: "QA" },
    { label: "GaugeSection", value: "GaugeSection" },
    { label: "IQA", value: "IQA" },
    { label: "QC", value: "QC" },
    { label: "DE", value: "DE" },
    { label: "RD", value: "RD" },
    { label: "PDA", value: "PDA" },
    { label: "PE", value: "PE" },
    { label: "DT", value: "DT" },
    { label: "PID", value: "PID" },
    { label: "SPC", value: "SPC" }

  ];


  useEffect(() => {

    if (localStorage.getItem("DEPARTMENT") === null || localStorage.getItem("DEPARTMENT") === "") {
      form.setFieldsValue({
        "dummy": options?.map((item) => item?.value),
      });
    }
    else if (localStorage.getItem("DEPARTMENT") === "MF") {

      form.setFieldsValue({
        "dummy": ["CF", "STP"],
      });
      setTimeout(() => {
        setValue(["CF", "STP"])
      }, 500)

    }
    else {
      form.setFieldsValue({
        "dummy": localStorage.getItem("DEPARTMENT"),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1)
  };
  return (
    <Fragment>
      <Row className="sub-head">
        <Col md={document.location.pathname === "/environmental_condition_monitoring" ? 8 : 10} xs={24}>

          <Row className="backArrow">
            <Col md={24} className="inside-head">
              {document.location.pathname === "/environmental_condition_monitoring" || document.location.pathname === "/machine_condition_monitoring" ? null :
                <a onClick={handleGoBack} className="left-to-right-animation">
                  <Image name="backArrow.svg" className="back" />
                </a>}



              <h2>
                {document.location.pathname === "/environmental_condition_monitoring"
                  ? "Environmental Condition Monitoring (EnvCM)"
                  : null}
                {document.location.pathname === "/machine_condition_monitoring"
                  ? "Machine Condition Monitoring (MCM)"
                  : null}
                {document.location.pathname === "/configuremap"
                  ? "Environmental Condition Monitoring (EnvCM) - Floor List"
                  : null}
                {document.location.pathname === "/FormFloorPlan"
                  ? "Environmental Condition Monitoring (EnvCM) - Floor Edit"
                  : null}
                {document.location.pathname === "/floormap"
                  ? "Environmental Condition Monitoring (EnvCM) - Floor Mapping"
                  : null}
              </h2>
            </Col>

          </Row>

        </Col>
        {/* <Col md={7} xs={12} className="flex-align border txt-right">
       
         
        </Col> */}
        <Col md={document.location.pathname === "/environmental_condition_monitoring" ? 16 : 14} xs={12} >
          <div className="sub-head-buttons" style={{ float: "right", paddingRight: "10px" }}>

            {/* {localStorage.getItem("DEPARTMENT") === "M_F" ?
              <Select mode="multiple" allowClear placeholder="Please select" defaultValue={['M_F']} onChange={handleChange} options={options} />
              : null} */}

            {document.location.pathname === "/environmental_condition_monitoring"
              || document.location.pathname === "/machine_condition_monitoring"
              || document.location.pathname === "/environmental_condition_monitoring"
              ? <Fragment>
                {localStorage.getItem("ROLE") === "admin" ?
                  <Fragment>
                    <div style={{ width: "250px",  marginRight: "20px" }}>
                      <Form
                        form={form}
                        ref={Ref as any}
                        name="info"
                        layout="vertical"
                        style={{ width: "100%" }}>
                        <Form.Item name="dummy">

                          <Select className="random-fit"
                            showArrow={true}
                            mode="multiple"
                            allowClear
                            placeholder="Please select"
                            onChange={handleChange}
                            options={options} />
                        </Form.Item>
                      </Form>
                    </div>
                  </Fragment>

                  : null
                }</Fragment>
              : null}


            {document.location.pathname === "/environmental_condition_monitoring"
              || document.location.pathname === "/configuremap"
              || document.location.pathname === "/FormFloorPlan"
              ?
              <Fragment>
                <Button className="date-icon" >
                  <Link to={"/floormap"}>
                    <h3 style={{ color: "#fff", fontSize: "13px" }}>Floor Map</h3>
                  </Link>
                </Button>

              </Fragment>

              : null}

            <Button className="date-icon"

            //  style={document.location.pathname === "/environmental_condition_monitoring"
            //   || document.location.pathname === "/configuremap"
            //   || document.location.pathname === "/FormFloorPlan" ? { width: '50%' } : { width: '100%' }}

            >
              <CalendarOutlined  />{moment().format("DD-MM-YYYY HH:mm A")}
            </Button>
          </div>

        </Col>

      </Row>
    </Fragment>
  );
}
