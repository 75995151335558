import React, { useState } from "react";
import PlcService from "../services/PlcService";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col } from "antd";

export default function CreatePlcFrom() {
  const [ipAddress, setIpaddress] = useState("");
  const [port, setPort] = useState("");
  const [department, setDepartment] = useState("");
  const [description, setDescription] = useState("");
  const [currentStatus, setCurrentStatus] = useState(0);
  const [loggerType, setLoggerType] = useState(0);

  async function prepPayLoadAndCreatePlc() {
    let requestPayLoad = {
      ipAddress,
      port,
      description,
      currentStatus,
      loggerType,
      department,
    };
    let isPlcCreated: boolean = await PlcService.createPLC(requestPayLoad);
    if (isPlcCreated) {
      alert("PLC Config Created Successfully");
      document.location.href = "/";
    }
  }

  return (
    <Row>
      <Col md={2} />
      <Col md={20} xs={24}>
        <form className="create-plc-form">
          <Row gutter={[16, 16]}>
            <Col md={24}>
              <h1><Link to="/environmental_condition_monitoring"><ArrowLeftOutlined /></Link> &nbsp;&nbsp; Add New Machine</h1>
            </Col>
            <Col md={8} xs={12}>
              <p className="form-label">Machine IP Address</p>
              <input
                type="text"
                placeholder=" 172.18.47.32"
                value={ipAddress}
                onChange={(e) => setIpaddress(e.target.value)}
              />
            </Col>
            <Col md={8} xs={12}>
              <p className="form-label"> TCP Port</p>
              <input
                type="number"
                placeholder=" 2323"
                value={port}
                onChange={(e) => setPort(e.target.value)}
              />
            </Col>
            <Col md={8} xs={12}>
              <p className="form-label"> Description</p>
              <input
                type="text"
                value={description}
                placeholder="Description "
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
            <Col md={8} xs={12}>
              <p className="form-label"> Department</p>
              <input
                type="text"
                value={department}
                placeholder=" CF"
                onChange={(e) => setDepartment(e.target.value)}
              />
            </Col>
            <Col md={8} xs={12}>
              <p className="form-label">Select Current Status</p>
              <select
                value={currentStatus}
                onChange={(e) => setCurrentStatus(parseInt(e.target.value))}
              >
                <option value={0}>UNABLE TO CONNECT</option>
                <option value={1}>CONNECTED</option>
              </select>
            </Col>
            <Col md={8} xs={12}>
              <p className="form-label">Select Logger Type</p>
              <select
                onChange={(e) => setLoggerType(parseInt(e.target.value))}
                value={loggerType}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </select>
            </Col>
            <Col md={6} xs={12} />

            <Col md={6} xs={12} className="txt-center">
              <Link to="/environmental_condition_monitoring">
                <div
                  className="btn btn-primarys"
                >
                  Cancel
                </div>
              </Link>
            </Col>
            <Col md={6} xs={12} className="txt-center">
              <div
                className="btn btn-primary"
                onClick={() => prepPayLoadAndCreatePlc()}
              >
                Create PLC
              </div>
            </Col>
            <Col md={6} xs={12} />
          </Row>
        </form>
      </Col>
      <Col md={2} />
    </Row>
  );
}
