import Header from "../../header/Header";
import DashboardHeader from "../components/DashboardHeader";
import DashboardItems from "../components/DashboardItems";
import { DashboardContextProvider } from "../providers/DashboardProvider";
import { getAllMachines } from "../services/DashboardService";
import { useLocation } from "react-router-dom";
import "../dashboard.css";
import { useEffect, useState } from "react";
import { redirectIfDepartmentIsNotSet, validateAndSetBaseURL } from "../../common/utils";


export default function DataBaseTwo() {

    // useEffect(() => {
    //     redirectIfDepartmentIsNotSet();
    //     validateAndSetBaseURL();
    // }, []);
    const search = useLocation().search;
    const auth = new URLSearchParams(search).get('auth_code');
    const depart = new URLSearchParams(search).get('dept_name');
    const [loading, setLoading] = useState(false)
    const [dropValue, setDropValues] = useState(false)
    const [machineList, setMachineList] = useState()

    const setValue = (value) => {
        setDropValues(value);
    }

    useEffect(() => {
        setLoading(false);
        getAllMachines(dropValue).then((machineList) => {
            if (machineList != null) {
                if (machineList.toString() == "You are not allowed to view the data") {
                    setMachineList(null)
                    setLoading(true);
                    return;
                }
            }
            setMachineList(machineList)
            setLoading(true);
        });
    }, [dropValue]); // eslint-disable-line



    useEffect(() => {
        validateAndSetBaseURL();
        if (auth !== null) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'authorisation_id': auth })
            };
            fetch('https://shimanoapi.corpfield.com/shimanov2/sadmin/authentication.php', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data?.status != "true") {
                        window.location.replace("/404");
                        return;
                    }
                    localStorage.setItem("ROLE", data?.role);
                    redirectIfDepartmentIsNotSet();
                });
        }
        else {
            if (depart !== null) {
                redirectIfDepartmentIsNotSet();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <Header />
            <DashboardContextProvider>
                <DashboardHeader setValue={setValue} />
                {machineList == null ?
                    <div className="nodata"><h1>No Data Found</h1></div> :
                    <DashboardItems loading={loading} machineList={machineList} />}
            </DashboardContextProvider>
        </div>
    );
}
